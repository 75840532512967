import { RTElementProp, RTMeetingCardElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import Spinner from '../../../Spinner';
import VideoChatPreviewCard from '../../../VideoChatPreviewCard';
import { IRTElementProps } from '../../IRTElementProps';
import VoidBlockElement from '../common/VoidBlockElement';

import { useMeetingCardElementQuery } from './useMeetingCardElementQuery';

const _VideoChatPreviewCard = styled(VideoChatPreviewCard)<{ $editable?: boolean }>`
  /* disable mouse events when element is in edit mode */
  pointer-events: ${({ $editable }) => ($editable ? 'none' : 'auto')};
`;

export default function MeetingCardElement(props: IRTElementProps<RTMeetingCardElement>) {
  const selected = useSelected();

  const meetingId = props.element[RTElementProp.MEETING_CARD__MEETING_ID];
  const offsetSeconds = +(props.element[RTElementProp.MEETING_CARD__OFFSET_SECONDS] ?? 0);

  const meetingQuery = useMeetingCardElementQuery(
    { id: meetingId ?? '', previewOffsetSeconds: offsetSeconds ? Math.floor(offsetSeconds) : null },
    { skip: !meetingId }
  );

  const meeting = meetingQuery.data?.meeting;
  if (!meeting) {
    return <Spinner />;
  }

  return (
    <VoidBlockElement attributes={props.attributes} slateChildren={props.children}>
      <_VideoChatPreviewCard
        $editable={props.editable}
        interactive={!props.editable}
        meeting={meeting}
        offsetSeconds={offsetSeconds}
        selected={selected}
      />
    </VoidBlockElement>
  );
}
