import { RTBulletedListElement } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';

const _Ul = styled.ul`
  margin: 0;
`;

export default function BulletedListElement(props: IRTElementProps<RTBulletedListElement>) {
  return <_Ul {...props.attributes}>{props.children}</_Ul>;
}
