import { LegacyPalette } from '@rmvw/c-common';
import { DefaultTheme } from 'styled-components';

import { ActivityStatus } from '../___generated___/globalTypes';

export function getStatusColor(theme: DefaultTheme, status?: ActivityStatus) {
  const colorMap: { [key in ActivityStatus]: string | undefined } = {
    ACTIVE: LegacyPalette.green60,
    AWAY: LegacyPalette.yellow60,
    INACTIVE: LegacyPalette.red60,
    UNKNOWN: LegacyPalette.white,
  };

  return status && colorMap[status];
}
