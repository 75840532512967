import { format, isSameDay, isSameYear } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

export enum TimestampFormat {
  EXTRA_SHORT = 'extraShort',
  SHORT = 'short',
  TIME = 'time',
}

export function getTimestampFormat(timestamp: Date, formatModifier?: TimestampFormat) {
  if (isSameDay(timestamp, new Date()) || formatModifier === TimestampFormat.TIME) {
    return 'h:mm aaa';
  }

  if (isSameYear(timestamp, new Date())) {
    return formatModifier === TimestampFormat.EXTRA_SHORT || formatModifier === TimestampFormat.SHORT
      ? 'MMM d'
      : 'MMM d, h:mm aaa';
  }

  return formatModifier === TimestampFormat.EXTRA_SHORT
    ? 'M/d/yyyy'
    : formatModifier === TimestampFormat.SHORT
    ? 'MMM d, yyyy'
    : 'MMM d, yyyy, h:mm aaa';
}

/**
 * Helper function that formats timestamp based on recency and format modifier.
 * In particular if the timestamp is part of the current year, it omits the year in the timestamp
 *
 * @param timestamp
 * @param formatModifier 'short' or 'time'
 * @returns
 */
export function formatTimestamp(timestamp: Date | string | number, formatModifier?: TimestampFormat) {
  const _timestamp = new Date(timestamp);
  return format(_timestamp, getTimestampFormat(_timestamp, formatModifier));
}

export interface ITimestampProps extends React.HTMLAttributes<HTMLSpanElement> {
  format?: TimestampFormat;
  timestamp: Date;
}

export default function Timestamp({ timestamp, ...props }: ITimestampProps) {
  return <TimestampDiv {...props}>{formatTimestamp(timestamp, props.format)}</TimestampDiv>;
}

export const TimestampDiv = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
`;
