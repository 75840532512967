import * as React from 'react';

const NULL_CALLBACK = () => null;

export interface IAppMainHeaderContext {
  mobileActionsCallback: () => JSX.Element | null;
  setMobileActionsCallback: (c: () => () => JSX.Element | null) => void;
  setTitle: (title: string | null) => void;
  title: string | null;
}

export const defaultContext: IAppMainHeaderContext = {
  mobileActionsCallback: NULL_CALLBACK,
  setMobileActionsCallback: NULL_CALLBACK,
  setTitle: NULL_CALLBACK,
  title: 'Loading...',
};

export const AppMainHeaderContext = React.createContext<IAppMainHeaderContext>(defaultContext);

export const AppMainHeaderProvider: React.FC = (props: React.PropsWithChildren<unknown>) => {
  const [mobileActionsCallback, setMobileActionsCallback] = React.useState<() => JSX.Element | null>(
    () => NULL_CALLBACK
  );
  const [title, setTitle] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (title) {
      window.document.title = title;
    }
  }, [title]);

  const MainHeaderProvider: IAppMainHeaderContext = React.useMemo(
    () => ({
      mobileActionsCallback,
      setMobileActionsCallback,
      setTitle,
      title,
    }),
    [mobileActionsCallback, title]
  );

  return <AppMainHeaderContext.Provider value={MainHeaderProvider}>{props.children}</AppMainHeaderContext.Provider>;
};

export const useAppMainHeaderManager = (): IAppMainHeaderContext => {
  return React.useContext(AppMainHeaderContext);
};

export const useTitle = (title: string | null): void => {
  const { setTitle } = useAppMainHeaderManager();
  React.useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
};

export const useMobileActionsCallback = (cb: (() => JSX.Element | null) | null): void => {
  const { setMobileActionsCallback } = useAppMainHeaderManager();
  React.useEffect(() => {
    setMobileActionsCallback(() => cb ?? NULL_CALLBACK);
  }, [cb, setMobileActionsCallback]);
};
