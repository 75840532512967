import * as React from 'react';
import type { SVGProps } from 'react';
import UntitledUIIconWrapper, { IIconWrapperProps } from '../UntitledUIIconWrapper';
function BracketsMinusOutlineIcon({
  className,
  role,
  size,
  ...props
}: IIconWrapperProps & Omit<SVGProps<SVGSVGElement>, 'height' | 'width' | 'strokeWidth'>) {
  return React.createElement(
    UntitledUIIconWrapper,
    {
      role,
      size,
      ...props,
    },
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, 'uiiIcon', 'uiIcon-BracketsMinusOutlineIcon'].filter((c) => !!c).join(' ')}
      {...props}
    >
      <path
        d="M18.5708 20C19.8328 20 20.8568 18.977 20.8568 17.714V13.143L21.9998 12L20.8568 10.857V6.286C20.8568 5.023 19.8338 4 18.5708 4M5.429 4C4.166 4 3.143 5.023 3.143 6.286V10.857L2 12L3.143 13.143V17.714C3.143 18.977 4.166 20 5.429 20M8 12H16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default BracketsMinusOutlineIcon;
