import * as React from 'react';

import BasePill, { IBasePillProps } from '../BasePill';

export type IAtMentionPillProps = Omit<IBasePillProps, 'icon'>;

function AtMentionPill(props: IAtMentionPillProps) {
  return <BasePill {...props}>@{props.children}</BasePill>;
}

export default AtMentionPill;
