import { gql, useQuery } from '@apollo/client';
import { CourbetDarkColorTheme, REQUEST_CONTEXT_SAMPLE_RATE } from '@rmvw/c-common';
import * as React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import { CanonicalSize } from '../../providers/ThemeProvider/themes';
import BaseEmoji from '../BaseEmojiPicker/BaseEmoji';
import DiscussionProfileIcon from '../Discussion/DiscussionProfileIcon';
import { LinkBrokenIcon, LockIcon } from '../Icon';
import SquareFacepile from '../ui/SquareFacepile';

import {
  CF_ProfileAvatar,
  ProfileAvatarProfile,
  ProfileAvatarProfileVariables,
} from './___generated___/ProfileAvatar.types';
import { Avatar, AvatarShape, IAvatarProps } from './Avatar';

export function getAvatarProps(
  theme: DefaultTheme,
  profile: CF_ProfileAvatar,
  size: number | undefined,
  iconSize?: CanonicalSize | number
) {
  // Use the profile's id to choose a random color
  const avatarColors = theme.color.avatarColors;
  const randomIndex =
    Math.abs(profile.id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % avatarColors.length;
  const backgroundColor = avatarColors[randomIndex];

  // Use iconSize if it exists. Otherwise determine iconSize based on size of the container
  const scaleFactor = 0.8;
  const _iconSize = iconSize ? iconSize : size ? size * scaleFactor : undefined;

  const profileAvatar = {
    backgroundColor,
    color: CourbetDarkColorTheme.background800,
    shape: undefined,
    src: profile.profileImage?.url,
  };

  switch (profile.__typename) {
    case 'PrivateChat': {
      return {
        backgroundColor: 'transparent',
        shape: 'square' as AvatarShape,
        src: (
          <SquareFacepile size={size}>
            {profile.participants
              .filter((p) => !p.isMe)
              .map((p) => (
                <ProfileAvatar key={p.id} profile={p} />
              ))}
          </SquareFacepile>
        ),
      };
    }

    case 'Discussion':
    case 'Meeting':
    case 'Replies': {
      return {
        backgroundColor: 'transparent',
        color: theme.color.lightPurple,
        shape: undefined,
        src: <DiscussionProfileIcon size={_iconSize} type={profile.icon} />,
      };
    }

    case 'MaskedTeam': {
      return {
        backgroundColor: 'transparent',
        color: theme.color.lightPurple,
        src: <LockIcon size={_iconSize} strokeWidth={1} />,
      };
    }
    case 'Team': {
      // 1f3e1 == 🏡
      return {
        backgroundColor: 'transparent',
        shape: 'square' as AvatarShape,
        src: <BaseEmoji size={_iconSize} unified={profile.icon ?? '1f3e1'} />,
      };
    }

    default: {
      return profileAvatar;
    }
  }
}

export interface IProfileAvatarProps extends Omit<IAvatarProps, 'src'> {
  iconSize?: CanonicalSize | number; // Used to override default icon size determined by size
  profile: CF_ProfileAvatar | null | undefined;
}

export function ProfileAvatar({ profile, size, iconSize, ...props }: IProfileAvatarProps) {
  const theme = useTheme();

  if (!profile) {
    // TODO: better fallback
    return <Avatar {...props} backgroundColor={'red'} color={'white'} size={size} src={<LinkBrokenIcon />} />;
  }

  const { backgroundColor, color, shape, src } = getAvatarProps(theme, profile, size, iconSize);

  return (
    <_Avatar
      {...props}
      alt={props.alt ?? profile.name}
      backgroundColor={backgroundColor}
      color={color}
      shape={shape ?? props.shape}
      size={size}
      src={src}
    />
  );
}

const BaseProfileAvatarFragment = gql`
  fragment CF_BaseProfileAvatar on IProfile {
    id
    icon
    name
    profileImage {
      id
      url(size: { width: 256, height: 256 })
    }
  }
`;

ProfileAvatar.fragment = gql`
  fragment CF_ProfileAvatar on IProfile {
    ...CF_BaseProfileAvatar

    ... on PrivateChat {
      participants {
        isMe
        ...CF_BaseProfileAvatar
      }
    }
  }
  ${BaseProfileAvatarFragment}
`;

ProfileAvatar.query = gql`
  ${ProfileAvatar.fragment}
  query ProfileAvatarProfile($id: ID!) {
    profile(id: $id) {
      id
      ...CF_ProfileAvatar
    }
  }
`;

///

export interface IProfileAvatarByIdProps extends Omit<IProfileAvatarProps, 'profile'> {
  id: string;
}

export function ProfileAvatarById({ id, ...props }: IProfileAvatarByIdProps) {
  const { data, loading } = useQuery<ProfileAvatarProfile, ProfileAvatarProfileVariables>(ProfileAvatar.query, {
    variables: { id },
    context: { [REQUEST_CONTEXT_SAMPLE_RATE]: 0 },
  });
  return <ProfileAvatar {...props} isLoading={loading} profile={data?.profile} />;
}

const _Avatar = styled(Avatar)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
