import { RTElementProp, RTHeadingElement } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';

const HIGHEST_HEADING_LEVEL = 1;
const LOWEST_HEADING_LEVEL = 6;

// Keep headings simple simple for now. Note we explicitly use h1, h2, h3 to preserve
// semantic meaning for screen readers, etc.

const baseHeadingStyle = `
  color: unset;
  font-size: 1em;
  margin: 1em 0 0.5em 0;
  padding: 0;

  :first-child {
    margin-top: 0;
  }
`;

const H1 = styled.h1`
  ${baseHeadingStyle}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const H2 = styled.h2`
  ${baseHeadingStyle}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const H3 = styled.h3`
  ${baseHeadingStyle}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default function HeadingElement(props: IRTElementProps<RTHeadingElement>) {
  // Normalize heading level to be between 1 and 6
  const level = Math.max(
    Math.min(+(props.element[RTElementProp.HEADING__LEVEL] ?? '1'), LOWEST_HEADING_LEVEL),
    HIGHEST_HEADING_LEVEL
  );

  switch (level) {
    case 1:
      return <H1 {...props.attributes}>{props.children}</H1>;
    case 2:
      return <H2 {...props.attributes}>{props.children}</H2>;
    default:
      // For now collapse h3...h6 -> h3
      return <H3 {...props.attributes}>{props.children}</H3>;
  }
}
