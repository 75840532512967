import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import * as React from 'react';

import { TypeaheadProfileQueryProfileType } from '../../../___generated___/globalTypes';
import { ProfileAvatar } from '../../Avatar';

import {
  CF_ProfileNameTypeaheadOptionsQueryProfile,
  ProfileNameTypeaheadOptionsQuery_Selected,
  ProfileNameTypeaheadOptionsQuery_SelectedVariables,
  ProfileNameTypeaheadOptionsQuery_Typeahead,
  ProfileNameTypeaheadOptionsQuery_TypeaheadVariables,
} from './___generated___/useProfileNameTypeaheadOptionsQuery.types';

const DEFAULT_AVATAR_SIZE = 32;

export type IProfileNameTypeaheadOption = CF_ProfileNameTypeaheadOptionsQueryProfile & {
  disabled?: boolean;
  searchMetadata?: {
    highlights: { name: string | null; highlights: readonly string[] } | null;
    score: number | null;
  };
};

interface IUseProfileNameTypeaheadProfileOptionsQueryProps {
  avatarSize?: number;
  enableNullQuery?: boolean;
  limit?: number;
  profileTypes?: TypeaheadProfileQueryProfileType[];
  query: string;
  scopeHint?: string[];
  selectedIds?: string[];
}

/**
 * Retrieve profile data for a set of ids.
 */
export function useProfileNameTypeaheadOptionsByIdQuery(
  ids?: ReadonlyArray<string>,
  queryOptions?: QueryHookOptions<
    ProfileNameTypeaheadOptionsQuery_Selected,
    ProfileNameTypeaheadOptionsQuery_SelectedVariables
  >
) {
  const { data, error, loading } = useQuery<
    ProfileNameTypeaheadOptionsQuery_Selected,
    ProfileNameTypeaheadOptionsQuery_SelectedVariables
  >(ProfileNameTypeaheadSelectedQuery, {
    ...queryOptions,
    skip: !ids?.length,
    variables: { preloadIds: ids },
  });

  const options = React.useMemo(
    () => data?.profiles?.map((profile) => profile ?? null).filterNullish() ?? [],
    [data?.profiles]
  );

  return { error, loading, options };
}

export function useProfileNameTypeaheadOptionsQuery({
  avatarSize = DEFAULT_AVATAR_SIZE,
  query,
  ...props
}: IUseProfileNameTypeaheadProfileOptionsQueryProps) {
  const typeaheadOptions = React.useRef<IProfileNameTypeaheadOption[] | null>(null);
  const { data, ...results } = useQuery<
    ProfileNameTypeaheadOptionsQuery_Typeahead,
    ProfileNameTypeaheadOptionsQuery_TypeaheadVariables
  >(ProfileNameTypeaheadQuery, {
    fetchPolicy: 'cache-and-network', // balance of speed and freshness
    skip: !query && !props.enableNullQuery,
    variables: {
      limit: props.limit,
      profileTypes: props.profileTypes,
      query,
      scopeHint: props.scopeHint,
    },
  });

  if (data) {
    // Use ref to avoid render flicker when options change.
    typeaheadOptions.current =
      data?.profileNameTypeaheadQuery
        ?.map((result) =>
          result?.profile
            ? { ...result?.profile, searchMetadata: { highlights: result.highlights, score: result.score } }
            : null
        )
        .filterNullish() ?? [];
  }

  return {
    ...results,
    typeaheadOptions: typeaheadOptions.current ?? [],
  };
}

useProfileNameTypeaheadOptionsQuery.fragment = gql`
  ${ProfileAvatar.fragment}
  fragment CF_ProfileNameTypeaheadOptionsQueryProfile on IProfile {
    id
    org {
      id
      name
    }
    permalink

    ... on Account {
      privateChat {
        id
      }
    }

    ... on Discussion {
      audience
      lastEvent {
        id
      }
    }

    ... on Team {
      audience
      lastEvent {
        id
      }
      membership {
        account {
          id
          isMe
        }
      }
    }

    ...CF_ProfileAvatar
  }
`;

export const ProfileNameTypeaheadSelectedQuery = gql`
  ${useProfileNameTypeaheadOptionsQuery.fragment}
  query ProfileNameTypeaheadOptionsQuery_Selected($preloadIds: [ID]) {
    # profiles to pre-load
    profiles(ids: $preloadIds) {
      ...CF_ProfileNameTypeaheadOptionsQueryProfile
    }
  }
`;

export const ProfileNameTypeaheadQuery = gql`
  ${useProfileNameTypeaheadOptionsQuery.fragment}
  query ProfileNameTypeaheadOptionsQuery_Typeahead(
    $limit: Int
    $profileTypes: [TypeaheadProfileQueryProfileType]
    $query: String!
    $scopeHint: [ID!]
  ) {
    profileNameTypeaheadQuery(
      filters: { profileTypes: $profileTypes }
      limit: $limit
      query: $query
      scopeHint: $scopeHint
    ) {
      profile {
        ...CF_ProfileNameTypeaheadOptionsQueryProfile
      }

      highlights {
        name
        highlights
      }

      score
    }
  }
`;
