import { LegacyPalette } from '@rmvw/c-common';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { hexColorWithAlpha } from '../../lib/css';

const highlightAnimation = () => keyframes`
  0% {
    background-color: ${hexColorWithAlpha(LegacyPalette.yellow100, 0.25)};
  }

  100% {
    background-color: ${hexColorWithAlpha(LegacyPalette.yellow20, 0)};
  }
`;

const _Highlight = styled.div<{ $delay: number }>`
  &.highlight {
    animation: ${highlightAnimation} 3s;
    animation-delay: ${({ $delay }) => $delay}ms;
  }
`;

export interface IHighlightProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Delay in ms before starting animation.
   */
  delay?: number;
  highlight?: boolean;
}

/**
 * Container that animates a highlight color (once) to draw the user's attention.
 */
function Highlight({ delay = 500, highlight, ...props }: IHighlightProps) {
  if (!highlight) {
    return <>{props.children}</>;
  }

  return <_Highlight {...props} className={[props.className, 'highlight'].join(' ')} $delay={delay} />;
}

export default Highlight;
