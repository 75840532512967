import { IS_STAGING, RTElement, RTElementType } from '@rmvw/x-common';
import * as React from 'react';

import Logger from '../../../lib/observability/Logger';
import { IRTElementProps } from '../IRTElementProps';

import BlockquoteElement from './BlockquoteElement';
import BulletedListElement from './BulletedListElement';
import CodeElement from './CodeElement';
import DefaultElement from './DefaultElement';
import DiscussionCardElement from './DiscussionCardElement';
import EmojiElement from './EmojiElement';
import FootnoteElement from './FootnoteElement';
import HeadingElement from './HeadingElement';
import ImageElement from './ImageElement';
import LinkCardElement from './LinkCardElement';
import ListItemElement from './ListItemElement';
import MediaElement from './MediaElement';
import MeetingCardElement from './MeetingCardElement';
import MessageRefElement from './MessageRefElement';
import OrderedListElement from './OrderedListElement';
import AccountRefElement from './pills/AccountRefElement';
import DiscussionRefElement from './pills/DiscussionRefElement';
import ExternalResourceRefElement from './pills/ExternalResourceRefElement';
import FileElement from './pills/FileElement';
import LinkElement from './pills/LinkElement';
import MeetingRefElement from './pills/MeetingRefElement';
import TeamRefElement from './pills/TeamRefElement';
import TableCellElement from './table/TableCellElement';
import TableElement from './table/TableElement';
import TableGroupBodyElement from './table/TableGroupBodyElement';
import TableGroupHeaderElement from './table/TableGroupHeaderElement';
import TableHeaderElement from './table/TableHeaderElement';
import TableRowElement from './table/TableRowElement';
import ThreadEventCardElement from './ThreadEventCardElement/ThreadEventCardElement';

export default function ElementRenderer(props: IRTElementProps<RTElement>): JSX.Element {
  switch (props.element.type) {
    case RTElementType.ACCOUNT_REF:
      return <AccountRefElement {...props} element={props.element} />;

    case RTElementType.BLOCKQUOTE:
      return <BlockquoteElement {...props} element={props.element} />;

    case RTElementType.BULLETED_LIST:
      return <BulletedListElement {...props} element={props.element} />;

    case RTElementType.CODE:
      return <CodeElement {...props} element={props.element} />;

    case RTElementType.DISCUSSION_CARD:
      return <DiscussionCardElement {...props} element={props.element} />;

    case RTElementType.DISCUSSION_REF:
      return <DiscussionRefElement {...props} element={props.element} />;

    case RTElementType.DIV:
      return <DefaultElement {...props} element={props.element} />;

    case RTElementType.EXTERNAL_RESOURCE_REF:
      return <ExternalResourceRefElement {...props} element={props.element} />;

    case RTElementType.EMOJI:
      return <EmojiElement {...props} element={props.element} />;

    case RTElementType.FILE:
      return <FileElement {...props} element={props.element} />;

    case RTElementType.FOOTNOTE:
      return <FootnoteElement {...props} element={props.element} />;

    case RTElementType.HEADING:
      return <HeadingElement {...props} element={props.element} />;

    case RTElementType.IMAGE:
      return <ImageElement {...props} element={props.element} />;

    case RTElementType.LINK:
      return <LinkElement {...props} element={props.element} />;

    case RTElementType.LINK_CARD:
      return <LinkCardElement {...props} element={props.element} />;

    case RTElementType.LIST_ITEM:
      return <ListItemElement {...props} element={props.element} />;

    case RTElementType.MEDIA:
      return <MediaElement {...props} element={props.element} />;

    case RTElementType.MEETING_CARD:
      return <MeetingCardElement {...props} element={props.element} />;

    case RTElementType.MEETING_REF:
      return <MeetingRefElement {...props} element={props.element} />;

    case RTElementType.ORDERED_LIST:
      return <OrderedListElement {...props} element={props.element} />;

    case RTElementType.MESSAGE_REF:
      return <MessageRefElement {...props} element={props.element} />;

    case RTElementType.TABLE:
      return <TableElement {...props} element={props.element} />;

    case RTElementType.TABLE_CELL:
      return <TableCellElement {...props} element={props.element} />;

    case RTElementType.TABLE_GROUP_BODY:
      return <TableGroupBodyElement {...props} element={props.element} />;

    case RTElementType.TABLE_GROUP_HEADER:
      return <TableGroupHeaderElement {...props} element={props.element} />;

    case RTElementType.TABLE_HEADER:
      return <TableHeaderElement {...props} element={props.element} />;

    case RTElementType.TABLE_ROW:
      return <TableRowElement {...props} element={props.element} />;

    case RTElementType.TEAM_REF:
      return <TeamRefElement {...props} element={props.element} />;

    case RTElementType.THREAD_EVENT_CARD:
      return <ThreadEventCardElement {...props} element={props.element} />;

    default: {
      if (IS_STAGING) {
        Logger.warn(`[ElementRenderer]: Unhandled element type: ${(props.element as any)?.type}`);
      }
      return <DefaultElement {...props} element={props.element} />;
    }
  }
}
