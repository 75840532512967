/* eslint-disable no-restricted-imports */

import { exhaustive } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { hexColorBlend } from '../../../lib/css';
import { CanonicalSize, CanonicalSizePx } from '../../../providers/ThemeProvider/themes';

import BaseButton, { IBaseButtonProps } from './BaseButton';

export type ButtonVariant = 'danger' | 'primary' | 'info' | 'success' | 'warning' | 'callToAction';
export type ButtonShape = 'square' | 'round' | 'circle';
export type ButtonSize = CanonicalSize;
export type ButtonType = 'link' | 'default' | 'primary' | 'ghost';

interface IStyledButtonProps {
  $hasBorder?: boolean;
  $isIconOnly: boolean;
  $shape: ButtonShape;
  $size: ButtonSize;
  $type: ButtonType;
  $variant: ButtonVariant;
}

const _Button = styled(BaseButton)<IStyledButtonProps>`
  align-items: center;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  display: flex;
  font-size: unset;
  gap: 4px;
  justify-content: center;
  line-height: 1;

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.color.variant.primary.color};
    outline-offset: 1px;
  }

  /**
   * Variant styles
   */
  ${({ theme, $type, $variant }) => {
    switch ($type) {
      case 'primary':
        return `
          background-color: ${theme.color.variant[$variant].buttonBackground ?? theme.color.variant[$variant].color};
          color: ${theme.color.variant[$variant].buttonColor ?? theme.color.color};

          &:focus,
          &:hover {
            filter: brightness(1.25);
          }

          &:active,
          &.active {
            filter: brightness(1.25);
          }

          &:disabled,
          &.disabled {
            background-color: ${hexColorBlend(theme.color.variant[$variant].color, theme.color.background400, 0.5)};
            color:${hexColorBlend(
              theme.color.variant[$variant].buttonColor ?? theme.color.color,
              theme.color.background400,
              0.5
            )};
          }
        `;

      case 'link':
        return `
          background-color: transparent;
          color: ${theme.color.variant[$variant].text};

          &:focus,
          &:hover {
            filter: brightness(1.25);
            text-decoration: underline;
          }

          &:active,
          &.active {
            filter: brightness(1.25);
            text-decoration: underline;
          }

          &:disabled,
          &.disabled {
            opacity: 0.65;
          }
        `;

      case 'ghost':
        return `
          background-color: transparent;
          color: ${theme.color.color};
          
          &:focus,
          &:hover {
            background-color: ${theme.color.background400};
            color: ${theme.color.color};
          }

          &:active,
          &.active {
            background-color: ${theme.color.background400};
            color: ${theme.color.color};
          }

          &:disabled,
          &.disabled {
            opacity: 0.65;
          }
        `;

      case 'default':
        return `
          background-color: ${theme.color.background400};
          color: ${$variant !== 'primary' ? theme.color.variant[$variant].text : theme.color.color};

          &:focus,
          &:hover {
            background-color: ${theme.color.background200};
            filter: brightness(1.25);
          }

          &:active,
          &.active {
            background-color: ${theme.color.background200};
            filter: brightness(1.25);
          }

          &:disabled,
          &.disabled {
            color: ${hexColorBlend(theme.color.color, theme.color.background200, 0.5)};
          }
        `;

      default:
        exhaustive($type, `Invalid type: ${$type}`);
    }
  }}

  /**
   * Border styles
   */
  ${({ $hasBorder, $type, $variant, theme }) => {
    if (!$hasBorder || $type === 'link') {
      // Ghost and link buttons don't have borders
      return `
        border-width: 0;

        &:focus,
        &:hover,
        &:active,
        &.active,
        &:disabled,
        &.disabled {
          border-width: 0;
        }
      `;
    }

    let borderColor: string;
    switch ($type) {
      case 'primary':
        borderColor = theme.color.variant[$variant].color;
        break;

      case 'ghost':
      case 'default':
        borderColor = theme.color.secondaryBorder;
        break;

      default:
        exhaustive($type, `Invalid type: ${$type}`);
    }

    return `
      border: 1px solid ${borderColor};

      &:focus,
      &:hover,
      &:active,
      &.active,
      &:disabled,
      &.disabled {
        border: 1px solid ${borderColor};
      }
    `;
  }}

  /**
   * Sizes
   */
  ${({ $size }) => {
    switch ($size) {
      case 'xLarge':
        return `
          font-size: 15px;
          height: ${CanonicalSizePx.xLarge}px;
          padding: 0 14px;
        `;

      case 'large':
        return `
          font-size: 13px;
          height: ${CanonicalSizePx.large}px;
          padding: 0 12px;
        `;

      case 'medium':
        return `
          font-size: 11px;
          height: ${CanonicalSizePx.medium}px;
          padding: 0 10px;
        `;

      case 'small':
        return `
          font-size: 11px;
          height: ${CanonicalSizePx.small}px;
          padding: 0 8px;
        `;

      case 'xSmall':
        return `
          font-size: 10px;
          height: ${CanonicalSizePx.xSmall}px;
          padding: 0 8px;
        `;

      case 'xxSmall':
        return `
          font-size: 9px;
          height: ${CanonicalSizePx.xxSmall}px;
          padding: 0 6px;
        `;

      default:
        exhaustive($size, `Invalid size: ${$size}`);
    }
  }}

  /**
   * Shapes
   */
  ${({ $isIconOnly, $shape, $size, theme }) => {
    const iconButtonStyles = $isIconOnly
      ? `
      aspect-ratio: 1;
      padding: 0;
    `
      : '';

    switch ($shape) {
      case 'circle':
        return `
          aspect-ratio: 1;
          border-radius: ${theme.borderRadius.xLarge};
          padding-left: 0;
          padding-right: 0;
          ${iconButtonStyles}
        `;

      case 'square': {
        let borderRadius: string;
        switch ($size) {
          case 'xLarge':
            borderRadius = '10px';
            break;

          case 'large':
            borderRadius = '8px';
            break;

          case 'medium':
            borderRadius = '6px';
            break;

          case 'small':
            borderRadius = '6px';
            break;

          case 'xSmall':
            borderRadius = '6px';
            break;

          case 'xxSmall':
            borderRadius = '4px';
            break;

          default:
            exhaustive($size, `Invalid size: ${$size}`);
        }
        return `
          border-radius: ${borderRadius};
          ${iconButtonStyles}
        `;
      }

      case 'round':
        return `
          border-radius: ${theme.borderRadius.xLarge};
          ${iconButtonStyles}
        `;

      default:
        exhaustive($shape, `Invalid shape: ${$shape}`);
    }
  }}

  &:disabled,
  &.disabled {
    pointer-events: none;
  }

  transition: background-color 0.25s, border-color 0.25s, color 0.25s, filter 0.25s;
`;

const _Content = styled.span`
  align-items: center;
  display: inline-flex;
  flex: 1;
  justify-content: center;
`;

/**
 * Rules for determining the size of the icon
 */
const _getIconSizePx = ({
  children,
  icon,
  iconWidth,
  size,
}: {
  children?: IButtonProps['children'];
  icon: IButtonProps['icon'];
  iconWidth: IButtonProps['iconWidth'];
  size: NonNullable<IButtonProps['size']>;
}): number => {
  if (iconWidth) {
    return iconWidth;
  }

  if (icon && !children) {
    // Icon only buttons use slightly larger icon size
    switch (size) {
      case 'xLarge':
        return 22;

      case 'large':
        return 18;

      case 'medium':
        return 16;

      case 'small':
        return 14;

      case 'xSmall':
        return 12;

      case 'xxSmall':
        return 10;

      default:
        exhaustive(size, `Invalid size ${size}`);
    }
  }

  switch (size) {
    case 'xLarge':
      return 16;

    case 'large':
      return 14;

    case 'medium':
      return 12;

    case 'small':
      return 11;

    case 'xSmall':
      return 10;

    case 'xxSmall':
      return 9;

    default:
      exhaustive(size, `Invalid size: ${size}`);
  }
};

export interface IButtonProps extends Omit<IBaseButtonProps, 'as' | 'size' | 'type' | 'variant'> {
  bordered?: boolean;
  icon?: JSX.Element;
  shape?: ButtonShape;
  size?: ButtonSize;
  type?: ButtonType;
  variant?: ButtonVariant;

  /**
   * Overrides the default `<Button />` icon width (in px)
   */
  iconWidth?: number;
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      bordered,
      children,
      icon,
      iconWidth,
      size = 'large',
      shape = 'square',
      type = 'default',
      variant = 'primary',
      ...props
    },
    ref
  ) => {
    return (
      <_Button
        {...props}
        $hasBorder={bordered}
        $isIconOnly={!!icon && !children}
        $shape={shape}
        $size={size}
        $type={type}
        $variant={variant}
        ref={ref}
      >
        {icon && React.cloneElement(icon, { size: _getIconSizePx({ children, icon, iconWidth, size }) })}
        {children && <_Content>{children}</_Content>}
      </_Button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
