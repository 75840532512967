import Logger from './observability/Logger';

export async function copyText(text: string) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    Logger.error(err as Error, '[copyText] Failed to copy permalink');
  }
}

export async function copyPermalink(path: string) {
  await copyText(`${window.location.origin}${path}`);
}
