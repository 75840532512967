import * as React from 'react';
import styled from 'styled-components';

import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';

const BORDER_WIDTH = '1.5px';

const _SquareFacepile = styled.div<{ $size: number }>`
  height: ${({ $size }) => $size}px;
  position: relative;
  width: ${({ $size }) => $size}px;
`;

const _Face = styled.div<{
  $showBorder: boolean;
  $size: number;
  $x: number;
  $y: number;
  $zIndex: number;
}>`
  ${({ $showBorder, theme }) => $showBorder && `border: ${BORDER_WIDTH} solid ${theme.color.background800};`}
  border-radius: 50%;
  height: ${({ $size }) => $size}px;
  left: ${({ $x }) => $x}px;
  position: absolute;
  top: ${({ $y }) => $y}px;
  width: ${({ $size }) => $size}px;
  z-index: ${({ $zIndex }) => $zIndex};
`;

const _OverflowTile = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.background500};
  border-radius: 50%;
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxxSmall};
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export interface ISquareFacepileProps extends React.PropsWithChildren<unknown> {
  size?: number;
}

function SquareFacepile({ children, size = CanonicalSizePx.medium }: ISquareFacepileProps) {
  const childrenArray = React.Children.toArray(children);
  if (childrenArray.length === 0) {
    return null;
  }

  const overflowCount = childrenArray.length - 4;
  const overflowTile = overflowCount > 0 ? <_OverflowTile>{overflowCount + 1}</_OverflowTile> : undefined;

  const facesToRender = childrenArray.slice(0, overflowTile ? 3 : 4);
  if (overflowTile) {
    facesToRender.push(overflowTile);
  }

  let faceSize = 0;
  switch (facesToRender.length) {
    case 1:
      faceSize = size;
      break;
    case 2:
      faceSize = size * (2 / 3);
      break;
    case 3:
    case 4:
      faceSize = size * (7 / 12);
      break;
    default:
      throw Error('Invalid number of children');
  }

  const placementHeuristic: Record<number, number[][]> = {
    1: [[0, 0]],
    2: [
      [0, 0],
      [size - faceSize, size - faceSize],
    ],
    3: [
      [size / 2 - faceSize / 2, 0],
      [0, size - faceSize],
      [size - faceSize, size - faceSize],
    ],
    4: [
      [0, 0],
      [size - faceSize, 0],
      [0, size - faceSize],
      [size - faceSize, size - faceSize],
    ],
  };
  const facePlacementMap = placementHeuristic[facesToRender.length];

  return (
    <_SquareFacepile $size={size}>
      {facesToRender.map((face, idx) => (
        <_Face
          key={idx}
          $showBorder={facesToRender.length > 1}
          $size={faceSize}
          $x={facePlacementMap[idx][0]}
          $y={facePlacementMap[idx][1]}
          $zIndex={facesToRender.length - idx}
        >
          {face}
        </_Face>
      ))}
    </_SquareFacepile>
  );
}

export default SquareFacepile;
