import { ApolloError, QueryHookOptions, gql, useQuery } from '@apollo/client';

import { EventFragmentsGQL } from '../../../thread/ThreadContent.gql';

import {
  ThreadEventPreviewQuery,
  ThreadEventPreviewQueryVariables,
} from './___generated___/useThreadEventPreviewQuery.types';

export const ThreadEventPreviewQueryGQL = gql`
  query ThreadEventPreviewQuery($input: ID!) {
    threadEvent(id: $input) {
      id
      ...ThreadEventFragments
    }
  }
  ${EventFragmentsGQL}
`;

export function useThreadEventPreviewQuery(
  input: ThreadEventPreviewQueryVariables,
  options?: Omit<QueryHookOptions<ThreadEventPreviewQuery, ThreadEventPreviewQueryVariables>, 'variables'>
): {
  loading: boolean;
  error?: ApolloError;
  data?: ThreadEventPreviewQuery;
} {
  return useQuery<ThreadEventPreviewQuery, ThreadEventPreviewQueryVariables>(ThreadEventPreviewQueryGQL, {
    ...options,
    variables: input,
  });
}
