import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { assertInEnum, inEnum } from '@rmvw/x-common';
import * as React from 'react';

import { GrantEntityInput, HasPermissionProfileEnum, MembershipStatus } from '../../../___generated___/globalTypes';

import {
  UpdateGrantsForEntityMutation,
  UpdateGrantsForEntityMutationVariables,
} from './___generated___/useUpdateGrantsForEntityMutation.types';

export const UpdateGrantsForEntityMutationGQL = gql`
  mutation UpdateGrantsForEntityMutation($targetId: ID!, $grantInput: UpdateGrantsForEntityInput) {
    updateGrantsForEntity(input: $grantInput) {
      id
      entity {
        ... on Team {
          id
          teamCanSee: canSee(target: { id: $targetId, type: Discussion })
          membership {
            account {
              id
              canSee(id: $targetId)
            }
          }
        }
        ... on Account {
          id
          accountCanSee: canSee(id: $targetId)
        }
      }
      target {
        id
        permissions {
          grants {
            entity {
              ... on IProfile {
                id
                name
              }
            }
            role
          }
        }
      }
      role
    }
  }
`;

interface IGrantForEntity {
  entity: GrantEntityInput;
  role: MembershipStatus;
}

export function useUpdateGrantsForEntityMutation(
  target?: { id: string; __typename: string } | null,
  options?: Omit<
    MutationHookOptions<UpdateGrantsForEntityMutation, UpdateGrantsForEntityMutationVariables>,
    'variables'
  >
) {
  const [mutation, mutationResult] = useMutation<UpdateGrantsForEntityMutation, UpdateGrantsForEntityMutationVariables>(
    UpdateGrantsForEntityMutationGQL
  );

  return React.useMemo(
    () =>
      [
        async (grants: ReadonlyArray<IGrantForEntity>, notifyAddedMembers?: boolean) => {
          if (!target) {
            return;
          }
          const targetType = target.__typename;
          if (!inEnum(targetType, HasPermissionProfileEnum)) {
            return;
          }
          assertInEnum(targetType, HasPermissionProfileEnum);

          return mutation({
            variables: {
              targetId: target.id,
              grantInput: {
                grants: grants.map((grant) => ({ ...grant, target: { id: target.id, type: targetType } })),
                notifyAddedMembers,
              },
            },
          });
        },
        mutationResult,
      ] as const,
    [mutation, mutationResult, target]
  );
}
