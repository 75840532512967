import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import * as React from 'react';

import { PinResourceMutation, PinResourceMutationVariables } from './___generated___/usePinResourceMutation.types';

export const PinResourceMutationGQL = gql`
  mutation PinResourceMutation($input: PinResourceInput!) {
    pinResource(input: $input) {
      id
      pinned
    }
  }
`;

export function usePinResourceMutation(
  resourceId?: string,
  options?: Omit<MutationHookOptions<PinResourceMutation, PinResourceMutationVariables>, 'variables'>
) {
  const [mutation, mutationResult] = useMutation<PinResourceMutation, PinResourceMutationVariables>(
    PinResourceMutationGQL,
    options
  );
  const _resourceId = resourceId;

  return React.useMemo(
    () =>
      [
        async (pinned: boolean, resourceId?: string) => {
          resourceId = resourceId || _resourceId;
          if (!resourceId) {
            throw new Error('[usePinResourceMutation] must provide resourceId');
          }
          return mutation({ variables: { input: { resourceId, pinned } } });
        },
        mutationResult,
      ] as const,
    [mutation, mutationResult, _resourceId]
  );
}
