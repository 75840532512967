import { ApolloCache, Reference } from '@apollo/client';

export const TYPING_TIMEOUT_MS = 2500;

type TypedThreadId = { id: string; __typename: 'Discussion' | 'Meeting' | 'PrivateChat' | 'Replies' | 'Team' };
const _key = (thread: TypedThreadId) => `${thread.__typename}::${thread.id}`;
export default class ThreadClientState {
  static _threadClientState = new Map<string, ThreadClientState>();

  static get(thread: TypedThreadId): ThreadClientState {
    return this._threadClientState.get(_key(thread)) || new ThreadClientState(thread);
  }

  threadId: string;
  threadType: string;
  visible?: boolean;
  sortTime?: Date;
  stationary?: boolean;
  typersSeenAt = new Map<string, number>();
  sweeper?: NodeJS.Timeout;

  constructor(thread: TypedThreadId) {
    this.threadId = thread.id;
    this.threadType = thread.__typename;
    ThreadClientState._threadClientState.set(_key(thread), this);
    return this;
  }

  public addTyper(cache: ApolloCache<object>, accountId: string, timeoutMs: number = TYPING_TIMEOUT_MS) {
    this.typersSeenAt.set(accountId, Date.now());
    if (!this.sweeper) {
      this.sweeper = setInterval(() => this._sweep(cache, timeoutMs), timeoutMs);
    }
  }

  private _sweep(cache: ApolloCache<object>, timeoutMs: number) {
    const now = Date.now();
    for (const [accountId, lastSeenAt] of this.typersSeenAt) {
      if (now - lastSeenAt >= timeoutMs) {
        this.removeTyper(cache, accountId);
        this.typersSeenAt.delete(accountId);
      }
    }

    if (this.typersSeenAt.size === 0) {
      clearInterval(this.sweeper);
      this.sweeper = undefined;
    }
  }

  public removeTyper(cache: ApolloCache<object>, accountId?: string | null) {
    if (!accountId) {
      return;
    }
    // remove from cache
    cache.modify({
      id: cache.identify({ __typename: this.threadType, id: this.threadId }),
      fields: {
        typing(cached: ReadonlyArray<Reference>, { readField }) {
          return cached.filter((u) => readField('id', u) != accountId);
        },
      },
    });
  }
}
