import { LegacyPalette } from '@rmvw/c-common';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { hexColorWithAlpha } from '../../lib/css';
import Duration from '../Duration';

export interface IThumbnailCardProps {
  timestampSeconds: number;
  thumbnailUrl?: string | null;
  width?: number;
}

function ThumbnailCard({ thumbnailUrl, timestampSeconds, width }: IThumbnailCardProps) {
  return (
    <_ThumbnailCard $width={width}>
      {thumbnailUrl && <_ThumbnailImage src={thumbnailUrl} />}
      <_ThumbnailTimestampContainer>
        <_ThumbnailTimestamp seconds={timestampSeconds} />
      </_ThumbnailTimestampContainer>
    </_ThumbnailCard>
  );
}

export const THUMBNAIL_CARD_DEFAULT_WIDTH_PX = 160;

const _ThumbnailCard = styled.div<{ $width?: number }>`
  aspect-ratio: 16 / 9;
  background-color: ${LegacyPalette.black};
  border: 1px solid ${LegacyPalette.gray50};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  overflow: hidden;
  position: relative;
  width: ${({ $width }) => $width ?? THUMBNAIL_CARD_DEFAULT_WIDTH_PX}px;
`;

const _ThumbnailImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const _ThumbnailTimestampContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

const durationStyle = css`
  background-color: ${hexColorWithAlpha(LegacyPalette.black, 0.4)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  color: ${LegacyPalette.white};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  padding: 0 2px;
`;

const _ThumbnailTimestamp = styled(Duration)`
  ${durationStyle}
`;

export default ThumbnailCard;
