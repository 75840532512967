import { parseISO } from 'date-fns';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ThreadResourceListItem from '../../ThreadDetails/ThreadResources/ThreadResourceListItem';
import Timestamp from '../../Timestamp';
import Space from '../../ui/Space';

import { CF_AssistantSourceSummaryChunkFragment } from './___generated___/AssistantResponseMessage.types';

const _ResourceContainer = styled.div`
  align-items: baseline;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const _PageNumber = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export interface IAssistantSourceSummaryChunkProps {
  chunk: CF_AssistantSourceSummaryChunkFragment;
  onClick?: () => void;
}

function AssistantSourceSummaryChunk({ chunk, onClick }: IAssistantSourceSummaryChunkProps) {
  const navigate = useNavigate();

  const _onOpen = React.useCallback(() => {
    const permalink =
      (chunk.__typename === 'ThreadResourceSummaryChunk'
        ? chunk.resource.threadEvents?.[0]?.permalink
        : chunk.permalink) ?? '#';
    navigate(permalink);
    onClick?.();
  }, [chunk, navigate, onClick]);

  let item: React.ReactNode;
  let time: Date | null;
  switch (chunk.__typename) {
    case 'ThreadResourceSummaryChunk':
      item = chunk.resource ? (
        <_ResourceContainer>
          <ThreadResourceListItem
            hideHoverActions
            hidePinnedIcon
            hideSharedBy
            onOpen={_onOpen}
            resource={chunk.resource}
          />
          {!!chunk.page && <_PageNumber>Page {chunk.page}.</_PageNumber>}
        </_ResourceContainer>
      ) : null;
      time = parseISO(chunk.resource.time);
      break;

    case 'ThreadSummaryChunk':
      item =
        chunk.thread?.__typename === 'Discussion' ||
        chunk.thread?.__typename === 'Meeting' ||
        chunk.thread?.__typename === 'Team' ? (
          <ThreadResourceListItem
            hideHoverActions
            hideSharedBy
            onOpen={_onOpen}
            // This is a HACK to leverage ThreadResourceListItem to render the thread
            // as a resource pill. We should refactor this to be more explicit. BUT we need
            // proper designs before it makes sense to spend too much time on this.
            resource={{
              __typename: 'ThreadResource',
              id: `resource-${chunk.thread.id}`,
              canEditLabel: false,
              label: chunk.thread.name,
              pinned: false,
              resource: chunk.thread,
              sharedBy: null,
              threadEvents: [
                {
                  __typename: 'MessageCreatedThreadEvent',
                  id: `event-${chunk.thread.id}`,
                  permalink: chunk.thread.permalink,
                  time: chunk.firstEventTime,
                },
              ],
              time: chunk.firstEventTime,
            }}
          />
        ) : null;
      time = parseISO(chunk.firstEventTime);
      break;

    default:
      item = null;
      time = null;
  }

  return (
    <Space align="baseline">
      {item}
      {time && <Timestamp timestamp={time} />}
    </Space>
  );
}

export default AssistantSourceSummaryChunk;
