import { ApolloError, gql, useQuery } from '@apollo/client';

import VideoChatPreviewCard from '../../../VideoChatPreviewCard';

import {
  BATCHED__MeetingCardElementQuery,
  BATCHED__MeetingCardElementQueryVariables,
} from './___generated___/useMeetingCardElementQuery.types';

export const MeetingCardElementQueryGQL = gql`
  ${VideoChatPreviewCard.CF_VideoChatPreviewCard}

  # Batched query
  query BATCHED__MeetingCardElementQuery($id: ID!, $previewOffsetSeconds: Int) {
    meeting(id: $id) {
      id
      ...CF_VideoChatPreviewCard

      # Custom preview image query to account for the previewOffsetSeconds
      media {
        id
        previewImage(offsetSeconds: $previewOffsetSeconds) {
          id
          url(size: { width: 800, height: 800 })
        }
      }
    }
  }
`;

export function useMeetingCardElementQuery(
  input: BATCHED__MeetingCardElementQueryVariables,
  options: { skip: boolean }
): {
  loading: boolean;
  error?: ApolloError;
  data?: BATCHED__MeetingCardElementQuery;
} {
  return useQuery<BATCHED__MeetingCardElementQuery, BATCHED__MeetingCardElementQueryVariables>(
    MeetingCardElementQueryGQL,
    {
      variables: input,
      ...options,
    }
  );
}
