import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BasicQueryStringUtils,
  LocalStorageBackend,
  RedirectRequestHandler,
} from '@openid/appauth';
import { OIDC_AUTH_REQUEST_PARAM_REF_ID } from '@rmvw/x-common';
import * as React from 'react';

import { IS_DESKTOP_APP } from '../../env';
import Logger from '../../lib/observability/Logger';

const DEFAULT_AUTHORIZATION_REQUEST_SCOPES = [
  'openid',
  'offline_access', // Allows use of refresh tokens
];

interface IAuthorizationRequestArgs {
  authConfig: AuthorizationServiceConfiguration;
  clientId: string;
  loginHint?: string;
  redirectUri: string;

  /**
   * Optional: reference id to pass to the authorization server.
   */
  refId?: string;
}

/**
 * Hook to perform an authorization request. Note the side effect of this hook is to
 * redirect the user to the authorization endpoint.
 */
export default function useAuthorizationRequest() {
  const startAuthorizationRequest = React.useCallback(
    ({ authConfig, clientId, loginHint, redirectUri, refId }: IAuthorizationRequestArgs) => {
      try {
        const authHandler = new RedirectRequestHandler(
          new LocalStorageBackend(),
          new BasicQueryStringUtils(),
          // Location shim for desktop app to force opening auth request in a new window
          IS_DESKTOP_APP ? { ...window.location, assign: (url: string) => window.open(url, '_blank') } : undefined
        );
        authHandler.performAuthorizationRequest(
          authConfig,
          new AuthorizationRequest({
            client_id: clientId,
            extras: {
              prompt: 'consent',
              ...(loginHint ? { login_hint: loginHint } : undefined),
              ...(refId ? { [OIDC_AUTH_REQUEST_PARAM_REF_ID]: refId } : undefined),
            },
            redirect_uri: redirectUri,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            scope: DEFAULT_AUTHORIZATION_REQUEST_SCOPES.join(' '),
          })
        );
      } catch (e) {
        Logger.error(e as Error, '[usePerformAuthorizationRequest] Authorization request error');
        throw e;
      }
    },
    []
  );

  return { startAuthorizationRequest };
}
