import * as React from 'react';
import OverlayTrigger, { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';

import BasePopover, { IBasePopoverProps, hideArrowModifier } from './BasePopover';

type PropsFromOverlay = Omit<OverlayTriggerProps, 'overlay'>;
type PropsFromPopover = Omit<IBasePopoverProps, 'arrowProps' | 'body' | 'children' | 'placement' | 'title'>;

export type PopoverPlacement = OverlayTriggerProps['placement'];

export interface IPopoverProps extends PropsFromOverlay, PropsFromPopover {
  content?: React.ReactNode;
  overlay?: JSX.Element;
  title?: React.ReactNode;
}

/**
 * `Popover` bundles a `BasePopover` with `OverlayTrigger` so that a popover can
 * easily be  attached to interactive elements like buttons. If more flexibility
 * is needed, use `BasePopover` along with an `Overlay` to customize rendering
 * and behavior.
 */
function Popover({
  children,
  content,
  flip = true,
  id,
  overlay,
  rootClose = true,
  title,
  trigger = 'click',
  ...props
}: IPopoverProps) {
  const modifiers = props.popperConfig?.modifiers || [];

  // Remove the offset since the arrow is hidden.
  modifiers.push(hideArrowModifier);

  return (
    <OverlayTrigger
      {...props}
      flip={flip}
      overlay={
        overlay || (
          <BasePopover id={id}>
            {title && <BasePopover.Header>{title}</BasePopover.Header>}
            <BasePopover.Body>{content}</BasePopover.Body>
          </BasePopover>
        )
      }
      popperConfig={{
        ...props.popperConfig,
        modifiers,
      }}
      rootClose={rootClose}
      trigger={trigger}
    >
      {children}
    </OverlayTrigger>
  );
}

export default Popover;
