import { RTElementProp, RTEmojiElement, getEmojiFromSlug } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import BaseEmoji from '../../../BaseEmojiPicker/BaseEmoji';
import { IRTElementProps } from '../../IRTElementProps';
import VoidInlineElement from '../common/VoidInlineElement';

const _EmojiSpan = styled(VoidInlineElement)<{ $selected: boolean }>`
  background-color: ${({ $selected, theme }) => ($selected ? theme.color.textSelectionBackground : undefined)};
  display: inline-flex;
  font-size: 1.25em;
  line-height: 1;
`;

export default function EmojiElement(props: IRTElementProps<RTEmojiElement>) {
  const selected = useSelected();

  const unified = props.element[RTElementProp.EMOJI__UNIFIED];
  const slug = props.element[RTElementProp.EMOJI__SLUG];

  return (
    <_EmojiSpan
      $selected={selected}
      attributes={props.attributes}
      slateChildren={props.children}
      title={slug && `:${slug}:`}
    >
      {unified ? <BaseEmoji unified={unified} /> : getEmojiFromSlug(slug)}
    </_EmojiSpan>
  );
}
