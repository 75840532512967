import styled from 'styled-components';

import { truncateText } from '../../../lib/css';
import Typeahead from '../Typeahead';

const ProfileTypeaheadResult = styled(Typeahead.Item)`
  align-items: center;
  display: flex;
  gap: 8px;
  line-height: 1.2;
  padding: 4px 16px;
`;

const ProfileTypeaheadResultContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

const ProfileTypeaheadResultName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.p};
  ${truncateText()}
`;

export default Object.assign(ProfileTypeaheadResult, {
  Content: ProfileTypeaheadResultContent,
  Name: ProfileTypeaheadResultName,
});
