import { LegacyPalette } from '@rmvw/c-common';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import OverflowMask from '../OverflowMask';

export interface IEmbeddedMessageProps extends React.PropsWithChildren<unknown> {
  author: string;
  maxHeight?: string;
  onClick?: () => void;
  target?: { name: string; permalink: string };
}

const _Blockquote = styled.blockquote<{ clickable: boolean }>`
  border-left: 4px solid ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.color};
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 4px;
  padding: 0 10px;

  ${({ theme, clickable }) => {
    if (clickable) {
      return `
        &:hover {
         border-left-color: ${LegacyPalette.yellow100};
         cursor: pointer;
        }
      `;
    }
  }}
`;

const _Author = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  height: min-content;
`;

function EmbeddedMessage(props: IEmbeddedMessageProps) {
  const threadElement = props.target && <Link to={props.target.permalink}>{props.target.name}</Link>;

  return (
    <_Blockquote onClick={props.onClick} clickable={!!props.onClick}>
      <OverflowMask maxHeight={props.maxHeight}>{props.children}</OverflowMask>
      <_Author>
        {props.author}
        {threadElement ? ' > ' : null}
        {threadElement}
      </_Author>
    </_Blockquote>
  );
}

export default EmbeddedMessage;
