import { exhaustive } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { CanonicalSize } from '../providers/ThemeProvider/themes';

const DEFAULT_STROKE_WIDTH = 1.2;

const _Icon = styled.span<{ $size?: number; $strokeWidth?: number }>`
  align-items: center;
  display: inline-flex;
  font-size: ${({ $size }) => ($size ? `${$size}px` : '1em')};

  & > svg {
    stroke-width: ${({ $strokeWidth }) => $strokeWidth ?? DEFAULT_STROKE_WIDTH};
  }
`;

export function getIconSize(size: CanonicalSize | number | undefined): number | undefined {
  if (size === undefined) {
    return undefined;
  }

  if (typeof size === 'number') {
    return size;
  }

  switch (size) {
    case 'xxSmall':
      return 12;
    case 'xSmall':
      return 16;
    case 'small':
      return 20;
    case 'medium':
      return 24;
    case 'large':
      return 32;
    case 'xLarge':
      return 40;
    default:
      exhaustive(size, `Invalid size ${size}`);
  }
}

export interface IIconWrapperProps {
  role?: string;
  size?: CanonicalSize | number;
  strokeWidth?: number;
}

/**
 * This component is a wrapper around the SVG icon components
 * to provide consistent sizing and stroke width.
 */
function IconWrapper({ children, role, size, strokeWidth }: React.PropsWithChildren<IIconWrapperProps>) {
  const _size = getIconSize(size);

  /**
   * Dynamically adjust stroke width based on size of
   * the icon. This formula is derived from the following
   * "Courbet" specs:
   *   - 12px: 1
   *   - 16px: 1.1
   *   - 24px: 1.2
   *   - 28px: 1.3
   *   - 32px: 1.4
   *   - 40px: 1.6
   */

  const _strokeWidth = strokeWidth || (_size && Math.round((0.0002 * _size ** 2 + 0.008 * _size + 0.9) * 20) / 20);

  return (
    <_Icon $size={_size} $strokeWidth={_strokeWidth} role={role}>
      {children}
    </_Icon>
  );
}

export default IconWrapper;
