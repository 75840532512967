import { gql, useQuery } from '@apollo/client';
import { RTDiscussionCardElement, RTElementProp } from '@rmvw/x-common';
import * as React from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

const LoadedMessagePreview = React.lazy(() => import('../../../Message/MessagePreview'));
const LoadedDiscussionPreview = React.lazy(() => import('../../../Discussion/DiscussionPreview'));
import Card from '../../../Card';
import Spinner from '../../../Spinner';
import { IRTElementProps } from '../../IRTElementProps';
import VoidInlineElement from '../common/VoidInlineElement';

import {
  BATCHED__DiscussionCardElement_DiscussionQuery,
  BATCHED__DiscussionCardElement_DiscussionQueryVariables,
  BATCHED__DiscussionCardElement_MessageQuery,
  BATCHED__DiscussionCardElement_MessageQueryVariables,
} from './___generated___/DiscussionCardElement.types';

const DiscussionQuery = gql`
  # Batched query
  query BATCHED__DiscussionCardElement_DiscussionQuery($id: ID!) {
    discussion(id: $id) {
      id
    }
  }
`;

const MessageQuery = gql`
  # Batched query
  query BATCHED__DiscussionCardElement_MessageQuery($id: ID!) {
    threadEvent(id: $id) {
      id
    }
  }
`;

export default function DiscussionCardElement(props: IRTElementProps<RTDiscussionCardElement>) {
  const editor = useSlateStatic();
  const selected = useSelected();
  const discussionQuery = useQuery<
    BATCHED__DiscussionCardElement_DiscussionQuery,
    BATCHED__DiscussionCardElement_DiscussionQueryVariables
  >(DiscussionQuery, {
    variables: { id: props.element[RTElementProp.DISCUSSION_CARD__DISCUSSION_ID] ?? '' },
    skip: !props.element[RTElementProp.DISCUSSION_CARD__DISCUSSION_ID],
  });
  const messageQuery = useQuery<
    BATCHED__DiscussionCardElement_MessageQuery,
    BATCHED__DiscussionCardElement_MessageQueryVariables
  >(MessageQuery, {
    variables: { id: props.element[RTElementProp.DISCUSSION_CARD__EVENT_ID] ?? '' },
    skip: !props.element[RTElementProp.DISCUSSION_CARD__EVENT_ID],
  });

  if (props.isPreview) {
    return null;
  }

  const deleteDiscussionCard = () => {
    const path = ReactEditor.findPath(editor as ReactEditor, props.element);
    Transforms.removeNodes(editor, { at: path });
  };

  const isMessage = !!props.element[RTElementProp.DISCUSSION_CARD__EVENT_ID];

  const canSee =
    (discussionQuery.loading || !!discussionQuery.data?.discussion?.id) &&
    (!isMessage || messageQuery.loading || !!messageQuery.data?.threadEvent?.id);
  if (!canSee) {
    deleteDiscussionCard();
    return null;
  }

  const fallbackCard = (
    <Card selected={selected}>
      <Card.Body>
        <Spinner fullScreen />
      </Card.Body>
    </Card>
  );

  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <React.Suspense fallback={fallbackCard}>
        {isMessage ? (
          <LoadedMessagePreview
            threadEventId={props.element[RTElementProp.DISCUSSION_CARD__EVENT_ID] ?? ''}
            interactive={!props.editable}
            selected={selected}
            onClose={deleteDiscussionCard}
          />
        ) : (
          <LoadedDiscussionPreview
            threadId={props.element[RTElementProp.DISCUSSION_CARD__DISCUSSION_ID] ?? ''}
            interactive={!props.editable}
            selected={selected}
            onClose={deleteDiscussionCard}
          />
        )}
      </React.Suspense>
    </VoidInlineElement>
  );
}
