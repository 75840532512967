import { asciiEmojiRegExp } from '@rmvw/x-common';

export const AT_MENTION_PREFIXES = ['@', '#'];
const COMMON_TLDS = ['com', 'org', 'net', 'edu', 'xyz', 'io', 'ai', 'dev', 'so', 'be', 'gov', 'info', 'tv'];

const LINK_REGEXP = new RegExp(
  `(?<![\`@.])\\b((?:https?:\\/\\/)?(?:[-\\w]+\\.)+(?:${COMMON_TLDS.join('|')})(?::\\d{1,5})?(?:\\/[^\\s]*)?)(?:\\b|$)`,
  'ig'
);

interface IMatchResult {
  match: string; // The matched link
  indexStart: number; // The index of the first character to include in the returned substring.
  indexEnd: number; // The index of the first character to exclude from the returned substring.
}

export function getLinkMatches(input: string): IMatchResult[] {
  const regex = new RegExp(LINK_REGEXP);
  const matches: IMatchResult[] = [];
  let match: RegExpExecArray | null;
  while ((match = regex.exec(input))) {
    matches.push({
      match: match[1],
      indexStart: match.index,
      indexEnd: match.index + match[1].length,
    });
  }

  return matches;
}

const DEFAULT_MENTION_MAX_WORDS = 3;

export function getMentionMatches(
  input: string,
  { maxWords }: { maxWords: number } = { maxWords: DEFAULT_MENTION_MAX_WORDS }
): IMatchResult[] {
  const regex = new RegExp(
    '(?:^|\\s|\\p{P})' + // Begin match after start of the string, whitespace, or punctuation
      '(' +
      `(?:[${AT_MENTION_PREFIXES.join('')}](?:[^\\s](?=\\s|$|\\p{P}(?:[\\s\\p{P}]|$))|[^\\s]+[^\\s\\p{P}]))` + // Match the @/# symbol followed by one or more non-whitespace characters that are NOT followed by punctuation and whitespace
      `(?:\\s+(?:.(?=\\s|$|\\p{P}(?:[\\s\\p{P}]|$))|[^${AT_MENTION_PREFIXES.join(
        ''
      )}][^\\s]*[^\\s\\p{P}])){0,${Math.max(maxWords - 1, 0)}}` + // Match one or more additional words that do not start with @/# symbol and are NOT followed by punctuation and whitespace
      '(\\s+$)?' + // Match any trailing whitespace
      ')',
    'gu'
  );
  const matches: IMatchResult[] = [];
  let match: RegExpExecArray | null;
  while ((match = regex.exec(input))) {
    const indexStart = match.index + match[0].indexOf(match[1]);
    matches.push({
      match: match[1],
      indexStart,
      indexEnd: indexStart + match[1].length,
    });
  }
  return matches;
}

export function getEmailMatches(input: string): IMatchResult[] {
  const regex = /(?<![`@])\b([\w.%+-_]+@[\w-.]+\.[A-Za-z]{2,})\b/g;
  const matches: IMatchResult[] = [];
  let match: RegExpExecArray | null;
  while ((match = regex.exec(input))) {
    matches.push({
      match: match[1],
      indexStart: match.index,
      indexEnd: match.index + match[1].length,
    });
  }
  return matches;
}

export function getEmojiMatches(input: string): IMatchResult[] {
  const regex = /(:[\w+-]+[:]?)/g;
  const matches: IMatchResult[] = [];

  // Match emoji shortcodes
  let match: RegExpExecArray | null;
  while ((match = regex.exec(input))) {
    matches.push({
      match: match[1],
      indexStart: match.index,
      indexEnd: match.index + match[1].length,
    });
  }

  // Also match ASCII emojis
  const asciiRegex = new RegExp(asciiEmojiRegExp);
  while ((match = asciiRegex.exec(input))) {
    const indexStart = match.index + match[0].indexOf(match[1]);
    matches.push({
      match: match[1],
      indexStart,
      indexEnd: indexStart + match[1].length,
    });
  }

  return matches;
}
