import { RTTextNodeMark } from '@rmvw/x-common';
import * as React from 'react';
import { RenderLeafProps } from 'slate-react';
import styled from 'styled-components';

export default function LeafRenderer(props: RenderLeafProps & { editable?: boolean }): JSX.Element {
  //
  // Merge the several formatting options that may be applicable to the Leaf node
  //

  let children = props.children;

  if (props.leaf[RTTextNodeMark.BOLD]) {
    children = <BoldText>{children}</BoldText>;
  }

  if (props.leaf[RTTextNodeMark.BOLDITALIC]) {
    children = <BoldItalicText>{children}</BoldItalicText>;
  }

  if (props.leaf[RTTextNodeMark.CODE]) {
    children = <CodeText>{children}</CodeText>;
  }

  if (props.leaf[RTTextNodeMark.ITALIC]) {
    children = <ItalicText>{children}</ItalicText>;
  }

  if (props.leaf[RTTextNodeMark.STRIKETHROUGH]) {
    children = <StrikethroughText>{children}</StrikethroughText>;
  }

  return (
    <BaseText {...props.attributes} {...props.leaf}>
      {children}
    </BaseText>
  );
}

const BoldText = styled.strong``;
const BoldItalicText = styled.strong`
  font-style: italic;
`;
const CodeText = styled.code`
  padding: 0;
`;
const ItalicText = styled.em``;
const StrikethroughText = styled.s``;

// BaseText is also responsible for rendering decorators -- styling that is applied at dynamically
// at render-time (e.g., syntax highlighting)
interface ICodeHighlightDecorators {
  ['_template-string']?: boolean;
  _comment?: boolean;
  _function?: boolean;
  _keyword?: boolean;
  _number?: boolean;
  _string?: boolean;
}

const BaseText = styled.span<ICodeHighlightDecorators>`
  ${(props) => (props._string || props['_template-string']) && 'color: #cf7922'}
  ${(props) => props._comment && 'color: #6e7781'}
  ${(props) => props._function && 'color: #2da44e'}
  ${(props) => props._keyword && 'color: #cf222e'}
  ${(props) => props._number && 'color: #aea300'}
`;
