import { gql, useQuery } from '@apollo/client';
import { exhaustive } from '@rmvw/x-common';
import * as React from 'react';

import { ThreadNotificationType } from '../../___generated___/globalTypes';

import { HQ_Wayfinders, HQ_WayfindersVariables } from './___generated___/useWayfinders.types';

export const WayfinderFragmentGQL = gql`
  fragment HF_Wayfinders on IThread {
    id
    notifications {
      id
      archived
      notificationType
      threadEvent {
        id
        permalink
        readAt
        time
      }
    }
  }
`;

const WayfinderQueryGQL = gql`
  ${WayfinderFragmentGQL}
  query HQ_Wayfinders($threadId: ID!) {
    thread(id: $threadId) {
      ...HF_Wayfinders
    }
  }
`;

export default function useWayfinders(threadId: string, loadedEvents: { time: Date }[]) {
  const { data, loading, error } = useQuery<HQ_Wayfinders, HQ_WayfindersVariables>(WayfinderQueryGQL, {
    variables: { threadId },
  });

  return React.useMemo(() => {
    function getWayfinderType(t: ThreadNotificationType) {
      switch (t) {
        case ThreadNotificationType.DISCUSSION_CREATED:
          return 'discussion' as const;
        case ThreadNotificationType.MEETING_CREATED:
          return 'meeting' as const;
        case ThreadNotificationType.MENTIONED:
          return 'mention' as const;
        case ThreadNotificationType.REPLY:
          return 'reply' as const;
        case ThreadNotificationType.UNREAD:
          return 'unread' as const;
        default:
          exhaustive(t, `[useWayfinders] Invalid ThreadNotificationType type: ${t}`);
      }
    }

    /**
     * Dedupe wayfinders for an event id.  If there are multiple matches it uses a priority ordering of
     *   mention > reply > unread
     * to select which one to return
     */
    const typePriority = {
      mention: 0,
      reply: 1,
      unread: 2,
      discussion: 3,
      meeting: 4,
    };

    const candidates = (data?.thread?.notifications || [])
      ?.filterNullish()
      .filter((n) => !n.archived)
      .map((n) => ({
        threadEventId: n.threadEvent.id,
        type: getWayfinderType(n.notificationType),
        time: n.threadEvent.time,
        index: -1,
      }));
    const eventToWayfinderMap = new Map<string, typeof candidates extends (infer K)[] ? K : never>();
    candidates.forEach((w) => {
      const existing = eventToWayfinderMap.get(w.threadEventId);
      if (!existing || typePriority[existing.type] > typePriority[w.type]) {
        eventToWayfinderMap.set(w.threadEventId, w);
      }
    });

    // sort by recency
    const wayfinders = [...eventToWayfinderMap.values()].sort(
      (a, b) => new Date(a.time).valueOf() - new Date(b.time).valueOf()
    );
    // .map((w, idx) => ({ ...w, index: idx }));
    // add index
    wayfinders.forEach((w, idx) => (w.index = idx));

    // identify any notifications that exist outside limits of currently fetched thread events
    const earliestThreadEventTime = Math.min(
      ...loadedEvents.map((e) => new Date(e.time).valueOf()),
      new Date().valueOf()
    );
    const latestThreadEventTime = Math.max(
      ...loadedEvents.map((e) => new Date(e.time).valueOf()),
      new Date().valueOf()
    );
    const earlierWayfinders = wayfinders.filter((n) => new Date(n.time).valueOf() < earliestThreadEventTime);
    const laterWayfinders = wayfinders.filter((n) => new Date(n.time).valueOf() > latestThreadEventTime);

    return {
      wayfinders,
      earlierWayfinders,
      laterWayfinders,
      getWayfinder: (eventId: string) => eventToWayfinderMap.get(eventId),
      total: wayfinders.length,
    };
  }, [data, loadedEvents]);
}

useWayfinders.fragment = WayfinderFragmentGQL;
