import * as React from 'react';
import RBDropdown, { DropdownProps } from 'react-bootstrap/Dropdown';
import { DropdownMenuProps } from 'react-bootstrap/DropdownMenu';
import { DropdownToggleProps as RBDropdownToggleProps } from 'react-bootstrap/DropdownToggle';
import styled from 'styled-components';

import { uppercaseText } from '../../lib/css';
import { ConditionalWrapper } from '../lib/ConditionalWrapper';
import Tooltip, { TooltipPlacement } from '../Tooltip';
import Button, { IButtonProps } from '../ui/Button';

import DropdownItem, { IDropdownItemProps } from './DropdownItem';

export type { DropdownProps, DropdownMenuProps, IDropdownItemProps };

const _Menu = styled(RBDropdown.Menu)`
  background-color: ${({ theme }) => theme.color.background600};
  border-color: ${({ theme }) => theme.color.secondaryBorder};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  filter: drop-shadow(0 0 4px ${({ theme }) => theme.color.dropShadow});
  padding: 8px;
`;

const _Header = styled(RBDropdown.Header)`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xxxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  padding: 6px 8px;
  ${uppercaseText()}
`;

const _Divider = styled(RBDropdown.Divider)`
  border-color: ${({ theme }) => theme.color.border};
`;

const _ToggleButton = styled(RBDropdown.Toggle)<{ $hideCaret?: boolean }>`
  &::after {
    display: ${({ $hideCaret }) => ($hideCaret ? 'none' : 'inline-block')} !important;
  }
`;

interface IBaseDropdownToggleProps
  extends Pick<RBDropdownToggleProps, 'as' | 'children' | 'disabled' | 'id' | 'split'> {
  hideCaret?: boolean;
  tooltip?: string | JSX.Element;
  tooltipPlacement?: TooltipPlacement;
}

export type IDropdownToggleProps<P = IButtonProps> = IBaseDropdownToggleProps & P;

function DropdownToggle<P = IButtonProps>({
  as = Button,
  hideCaret,
  tooltip,
  tooltipPlacement,
  ...props
}: IDropdownToggleProps<P>) {
  return (
    <ConditionalWrapper
      condition={!!tooltip}
      wrapper={({ children }) => (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          <span>{children}</span>
        </Tooltip>
      )}
    >
      <_ToggleButton {...props} forwardedAs={as} $hideCaret={hideCaret} />
    </ConditionalWrapper>
  );
}

const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>((props, ref) => {
  return <RBDropdown {...props} ref={ref} />;
});

Dropdown.displayName = 'Dropdown';

/**
 * Provides a low-level component set for rendering dropdowns. `DropdownButton`
 * and `SplitButton` can be used in most common cases, but when more
 * customization is needed, these pieces can be used.
 */
export default Object.assign(Dropdown, {
  Divider: _Divider,
  Header: _Header,
  Item: DropdownItem,
  Menu: _Menu,
  Toggle: DropdownToggle,
});
