import { gql, useQuery } from '@apollo/client';
import { isInternalLink } from '@rmvw/x-common';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Tooltip from '../../Tooltip';
import ResourcePill from '../../ui/Pills/ResourcePill';

import { ResourceRef_MessageQuery, ResourceRef_MessageQueryVariables } from './___generated___/ResourceRef.types';

const MessageQuery = gql`
  query ResourceRef_MessageQuery($id: ID!) {
    threadEvent(id: $id) {
      id
      permalink
      ... on MessageCreatedThreadEvent {
        message {
          id
          creator {
            id
          }
        }
      }
    }
  }
`;

export enum ResourceType {
  DISCUSSION = 'Discussion',
  EXTERNAL_RESOURCE = 'External Resource',
  MEETING = 'Meeting',
  PRIVATE_CHAT = 'Private Chat',
  TEAM = 'Team',
}

export interface IResourceRefProps {
  editable?: boolean;
  selected: boolean;
  resourceType: ResourceType;
  resourceId?: string;
  resourceTitle?: string;
  resourcePermalink?: string;
  threadEventId?: string;
  canSeeResource: boolean;
}

function ResourceRef(props: IResourceRefProps) {
  const messageQuery = useQuery<ResourceRef_MessageQuery, ResourceRef_MessageQueryVariables>(MessageQuery, {
    variables: { id: props.threadEventId ?? '' },
    skip: !props.threadEventId,
  });

  const isMessage = !!props.threadEventId;

  const canSee = props.canSeeResource && (!isMessage || messageQuery.loading || !!messageQuery.data?.threadEvent?.id);

  const content = (
    <ResourcePill
      isPrivate={!canSee}
      avatarId={
        isMessage && messageQuery.data?.threadEvent?.__typename === 'MessageCreatedThreadEvent'
          ? messageQuery.data?.threadEvent?.message?.creator?.id
          : props.resourceId
      }
      title={props.resourceTitle}
      isMessage={isMessage}
      selected={props.selected}
    />
  );

  if (canSee) {
    if (!props.editable) {
      // link-ify when NOT editing
      if (isInternalLink(props.resourcePermalink || '')) {
        return (
          <Link to={isMessage ? messageQuery.data?.threadEvent?.permalink ?? '.' : props.resourcePermalink ?? '.'}>
            {content}
          </Link>
        );
      } else {
        // open external links in new tab/browser
        return (
          <_Link href={props.resourcePermalink} target="_blank">
            {content}
          </_Link>
        );
      }
    } else {
      // do not link-ify when editing
      return content;
    }
  } else {
    // provide explanatory tooltip when cannot see
    return (
      <Tooltip
        title={`The ${
          isMessage ? 'message' : props.resourceType
        } no longer exists or you do not have permission to view it.`}
      >
        <span>{content}</span>
      </Tooltip>
    );
  }
}

export default ResourceRef;

const _Link = styled.a`
  &:hover {
    text-decoration: underline;
  }

  text-decoration: none;
`;
