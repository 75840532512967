import { ApolloClient } from '@apollo/client';
import {
  IRTFileElementNodeBaseResolverData,
  MAX_FILE_UPLOAD_SIZE_BYTES,
  RTDescendantNodes,
  RTElementProp,
  RTElementType,
  RTFileElementNode,
  RTImageElement,
  RTImageElementNode,
  RTLinkElementNode,
  RTMediaElement,
  RTMediaElementNode,
  getExternalResourceMetadataFromPath,
  getUnresolvedNodes,
} from '@rmvw/x-common';
import { filesize } from 'filesize';
import { Editor, Node, Path, Element as SlateElement, Transforms } from 'slate';

import { createImageFromURL, uploadFile } from '../Mutators';
import Logger from '../observability/Logger';

import type { RTEditor } from './Editor';

export interface IRTUploadElementNodeResolverData extends IRTFileElementNodeBaseResolverData {
  file: File;
}

const UploadFailedError = new Error('Upload failed');

export default class RTNodeResolver {
  /**
   * Asynchronously resolves nodes in RT editor, invoking callback when complete
   */
  static resolveNodes(
    editor: RTEditor,
    apolloClient: ApolloClient<object>,
    nodes: RTDescendantNodes,
    cbs?: {
      onResolverCompleted?: (resolverId: string) => void;
      onResolverError?: (resolverId: string, error: Error) => void;
      onResolverStarted?: (resolverId: string, data?: { file?: File }) => void;
    }
  ) {
    // Spin off an async resolver per unresolved node, firing a callback when complete
    getUnresolvedNodes(nodes).forEach((nodes, resolverId) => {
      // We need to process only first node of set sharing same id since they all get replaced together
      const node = nodes[0];

      // Verify that file does not exist maximum size limits before we start upload
      const data: IRTUploadElementNodeResolverData =
        node instanceof RTFileElementNode ||
        node instanceof RTImageElementNode ||
        node instanceof RTLinkElementNode ||
        node instanceof RTMediaElementNode
          ? node.getState().$_resolverContext?.data
          : undefined;
      if (!data) {
        Logger.error(`[RTNodeResolver] Missing data for resolver: ${resolverId}`);
      }

      // Mark upload as started
      cbs?.onResolverStarted?.(resolverId, data);

      if (data?.file && data.file.size > MAX_FILE_UPLOAD_SIZE_BYTES) {
        cbs?.onResolverError?.(
          resolverId,
          new Error(`File exceeds maximum allowed size (${filesize(MAX_FILE_UPLOAD_SIZE_BYTES)})`)
        );
        return;
      }

      if (node instanceof RTFileElementNode) {
        // Process arbitrary file upload request
        (async () => {
          try {
            // Note that a pasted <embed> will appear as a FileElementNode but data.url is not supported here
            const asset = data.file ? await uploadFile(apolloClient, { file: data.file }) : null;
            if (asset?.__typename !== 'File') {
              throw new Error(`[RTNodeResolver] Failed to upload File: ${data.file.name}`);
            }
            this._updateSlateNodes(editor, resolverId, {
              [RTElementProp.FILE__FILE_ID]: asset.id,
              [RTElementProp.__RESOLVER_ID]: undefined,
            });
            cbs?.onResolverCompleted?.(resolverId);
          } catch (e) {
            Logger.error(e as Error, '[RTNodeResolver] RTFileElementNode resolution failed');
            cbs?.onResolverError?.(resolverId, UploadFailedError);
          }
        })().catch((e) => Logger.error(e, '[RTNodeResolver] Failed to resolve RTFileElementNode'));
      } else if (node instanceof RTImageElementNode) {
        // Process image upload request
        (async () => {
          try {
            const asset = data.url
              ? await createImageFromURL(apolloClient, { url: data.url })
              : data.file
              ? await uploadFile(apolloClient, { file: data.file })
              : null;
            if (asset?.__typename !== 'Image') {
              throw new Error(`[RTNodeResolver] Failed to upload Image file: ${data.file.name}`);
            }
            this._updateSlateNodes(editor, resolverId, {
              [RTElementProp.IMAGE__IMAGE_ID]: asset.id,
              [RTElementProp.__RESOLVER_ID]: undefined,
            });
            cbs?.onResolverCompleted?.(resolverId);
          } catch (e) {
            Logger.error(e as Error, '[RTNodeResolver] RTImageElementNode resolution failed');
            cbs?.onResolverError?.(resolverId, UploadFailedError);
          }
        })().catch((e) => Logger.error(e, '[RTNodeResolver] Failed to resolve RTImageElementNode'));
      } else if (node instanceof RTLinkElementNode) {
        (async () => {
          try {
            // get app profiles, test for match - get extId
            const extMetadata = data.url && getExternalResourceMetadataFromPath(data.url);
            if (extMetadata) {
              const paths = this._getNodePaths(editor, resolverId);

              for (const path of paths) {
                Editor.withoutNormalizing(editor, () => {
                  Transforms.removeNodes(editor, { at: path });
                  Transforms.insertNodes(
                    editor,
                    {
                      type: RTElementType.EXTERNAL_RESOURCE_REF,
                      children: [{ text: `Resource@${data.url}` }],
                      [RTElementProp.EXTERNAL_RESOURCE_REF__URL]: data.url,
                    },
                    {
                      at: path,
                    }
                  );
                  Transforms.select(editor, { path: Path.next(path), offset: 0 });
                });
              }
            }
            this._updateSlateNodes(editor, resolverId, {
              [RTElementProp.__RESOLVER_ID]: undefined,
            });
            cbs?.onResolverCompleted?.(resolverId);
          } catch (e) {
            Logger.error(e as Error, '[RTNodeResolver] RTLinkElementNode resolution failed');
            cbs?.onResolverError?.(resolverId, UploadFailedError);
          }
        })().catch((e) => Logger.error(e, '[RTNodeResolver] Failed to resolve RTLinkElementNode'));
      } else if (node instanceof RTMediaElementNode) {
        // Process media upload request
        (async () => {
          try {
            const asset = data.file ? await uploadFile(apolloClient, { file: data.file }) : null;
            if (asset?.__typename !== 'Media') {
              throw new Error(`[RTNodeResolver] Failed to upload Media file: ${data.file.name}`);
            }
            this._updateSlateNodes(editor, resolverId, {
              [RTElementProp.IMAGE__IMAGE_ID]: asset.id,
              [RTElementProp.__RESOLVER_ID]: undefined,
            });
            cbs?.onResolverCompleted?.(resolverId);
          } catch (e) {
            Logger.error(e as Error, '[RTNodeResolver] RTMediaElementNode resolution failed');
            cbs?.onResolverError?.(resolverId, UploadFailedError);
          }
        })().catch((e) => Logger.error(e, '[RTNodeResolver] Failed to resolve RTMediaElementNode'));
      } else {
        throw new Error(`[RTNodeResolver] Unknown node type: ${node.constructor.name}`);
      }
    });
  }

  private static _updateSlateNodes(
    editor: RTEditor,
    resolverId: string,
    props: Partial<RTImageElement> | Partial<RTMediaElement>
  ) {
    const nodePaths = this._getNodePaths(editor, resolverId);
    for (const path of nodePaths) {
      Transforms.setNodes<Node>(editor, props, { at: path });
    }
  }

  private static _getNodePaths(editor: RTEditor, resolverId: string) {
    const nodeMatches = Editor.nodes(editor, {
      at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
      match: (n) => SlateElement.isElement(n) && n[RTElementProp.__RESOLVER_ID] === resolverId,
    });
    return [...nodeMatches].map(([node, path]) => path);
  }
}
