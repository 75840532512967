import { RTOrderedListElement } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';

const _Ol = styled.ol`
  margin: 0;

  li::marker {
    font-feature-settings: 'tnum' 1; /* Use tabular figures */
  }
`;

export default function OrderedListElement(props: IRTElementProps<RTOrderedListElement>) {
  return <_Ol {...props.attributes}>{props.children}</_Ol>;
}
