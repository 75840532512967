import {
  RTCodeElement,
  RTCodeElementNodeLanguage,
  RTCodeElementNodeLanguages,
  RTElementProp,
  RTElementType,
} from '@rmvw/x-common';
import * as React from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';
import styled from 'styled-components';

import Dropdown from '../../../Dropdown';
import { IRTElementProps } from '../../IRTElementProps';

const DEFAULT_LANGUAGE_LABEL = 'Text';

type Language = RTCodeElementNodeLanguage | null;

const _Toggle = styled.a.attrs({ role: 'button' })`
  background-color: ${({ theme }) => theme.color.background800};
  color: ${({ theme }) => theme.color.color};
  padding: 0 4px;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.color.color};
  }
`;

interface ILanguageSelectorProps {
  onSelect: (eventKey: Language) => void;
  selectedLanguage: Language;
}

function LanguageSelector({ onSelect, selectedLanguage }: ILanguageSelectorProps) {
  const items = [...Object.entries(RTCodeElementNodeLanguages)].map(([eventKey, label]) => ({
    eventKey,
    label,
  }));

  const popperConfig = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-8, 4],
        },
      },
    ],
    strategy: 'fixed',
  };

  return (
    <Dropdown onSelect={(eventKey) => onSelect(eventKey as Language)}>
      <Dropdown.Toggle as={_Toggle}>
        {selectedLanguage ? RTCodeElementNodeLanguages[selectedLanguage] : DEFAULT_LANGUAGE_LABEL}
      </Dropdown.Toggle>
      <Dropdown.Menu popperConfig={popperConfig} renderOnMount>
        <Dropdown.Item active={!selectedLanguage} eventKey={undefined}>
          Text
        </Dropdown.Item>
        <Dropdown.Divider />
        {items.map(({ eventKey, label }) => (
          <Dropdown.Item active={selectedLanguage === eventKey} eventKey={eventKey} key={eventKey}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default function CodeElement(props: IRTElementProps<RTCodeElement>) {
  const editor = useSlateStatic();
  const focused = useFocused();
  const selected = useSelected();

  if (props.element.type !== RTElementType.CODE) {
    return null;
  }

  const language = props.element[RTElementProp.CODE__LANGUAGE];
  const selectedLanguage = language && RTCodeElementNodeLanguages[language] ? language : null;

  return (
    <Container {...props.attributes}>
      {props.editable && focused && selected && (
        <CodeLegend contentEditable={false}>
          <LanguageSelector
            onSelect={(language: Language) => {
              Transforms.setNodes<RTCodeElement>(
                editor,
                { [RTElementProp.CODE__LANGUAGE]: language || undefined },
                { match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === RTElementType.CODE }
              );
            }}
            selectedLanguage={selectedLanguage}
          />
        </CodeLegend>
      )}
      <Code>{props.children}</Code>
    </Container>
  );
}

const Container = styled.fieldset`
  margin: 4px 0;
  position: relative;
`;

const CodeLegend = styled.legend`
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  left: 8px;
  margin: 0;
  position: absolute;
  top: -8px;
  user-select: none;
`;

const Code = styled.pre`
  margin: 0;
  padding: 4px;
  white-space: pre-wrap;
`;
