import * as React from 'react';
import styled from 'styled-components';

import FileUploadIcon from '../../../FileUploadIcon';
import { FileAttachmentErrorIcon } from '../../../Icon';
import BasePill, { IBasePillProps } from '../BasePill';

const _FilePill = styled(BasePill)<{ $hasError?: boolean }>`
  background-color: ${({ $hasError, theme }) => ($hasError ? theme.color.variant.danger.background : undefined)};
  color: ${({ $hasError, theme }) => ($hasError ? theme.color.variant.danger.color : undefined)};

  &:hover {
    /* effectively disable hover in error state */
    background-color: ${({ $hasError, theme }) => ($hasError ? theme.color.variant.danger.background : undefined)};
  }
`;

const _ErrorIcon = styled(FileAttachmentErrorIcon)`
  color: ${({ theme }) => theme.color.variant.danger.color};
`;

export interface IFilePillProps extends Omit<IBasePillProps, 'icon'> {
  contentType?: string;
  extension?: string;
  hasError?: boolean;
}

function FilePill({ contentType, extension, hasError, ...props }: IFilePillProps) {
  return (
    <_FilePill
      $hasError={hasError}
      icon={hasError ? <_ErrorIcon /> : <FileUploadIcon contentType={contentType} extension={extension} />}
      {...props}
    />
  );
}

export default FilePill;
