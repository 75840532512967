import * as React from 'react';
import styled from 'styled-components';

import { truncateText } from '../../../../lib/css';
import { CanonicalSizePx } from '../../../../providers/ThemeProvider/themes';
import { ProfileAvatarById } from '../../../Avatar';
import { LockIcon } from '../../../Icon';
import BasePill, { IBasePillProps } from '../BasePill';

const _Title = styled.span`
  ${truncateText}
`;

const _TitlePrefix = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export interface IResourcePillProps extends Omit<IBasePillProps, 'children' | 'icon'> {
  avatarId?: string;
  isMessage?: boolean;
  isPrivate?: boolean;
  title?: string;
}

function ResourcePill({ avatarId, isMessage, isPrivate, selected, title, ...props }: IResourcePillProps) {
  const icon = avatarId && <ProfileAvatarById id={avatarId} size={CanonicalSizePx.xxSmall} />;

  return !isPrivate && title ? (
    <BasePill icon={icon} selected={selected} {...props}>
      {isMessage ? (
        <_Title title={`Message in ${title}`}>
          <_TitlePrefix>Message in </_TitlePrefix>
          {title}
        </_Title>
      ) : (
        <_Title title={title}>{title}</_Title>
      )}
    </BasePill>
  ) : (
    <BasePill icon={<LockIcon />} nonInteractive selected={selected} {...props}>
      <_Title title="Private Resource">Private Resource</_Title>
    </BasePill>
  );
}

export default ResourcePill;
