import { gql, useQuery } from '@apollo/client';
import { RTElementProp, RTMessageRefElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import Logger from '../../../../lib/observability/Logger';
import EmbeddedMessage from '../../../EmbeddedMessage';
import Spinner from '../../../Spinner';
import { IRTElementProps } from '../../IRTElementProps';
import VoidInlineElement from '../common/VoidInlineElement';
import DefaultElement from '../DefaultElement/DefaultElement';

import {
  BATCHED__MessageRefElement_MessageQuery,
  BATCHED__MessageRefElement_MessageQueryVariables,
} from './___generated___/MessageRefElement.types';

// ... to avoid circular dependency
const RichText = React.lazy(() => import('../../RichText'));

const MessageQuery = gql`
  # Batched query
  query BATCHED__MessageRefElement_MessageQuery($id: ID!) {
    message(id: $id) {
      id
      creator {
        id
        name
      }
      body
      thread {
        id
        name
        permalink
      }
    }
  }
`;

/**
 * @deprecated
 */
export default function MessageRefElement(props: IRTElementProps<RTMessageRefElement>) {
  const selected = useSelected();
  const messageId = props.element[RTElementProp.MESSAGE_REF__MESSAGE_ID];
  const msgQuery = useQuery<BATCHED__MessageRefElement_MessageQuery, BATCHED__MessageRefElement_MessageQueryVariables>(
    MessageQuery,
    {
      variables: { id: messageId || '' },
      skip: !messageId,
    }
  );

  try {
    if (!messageId) {
      return props.children;
    }

    // note to self, since this is querying for message directly (vs getting it as part of an existing query) i believe
    // it will be a cache miss and go to server
    if (msgQuery.loading) {
      return <Spinner />;
    }

    if (msgQuery.error) {
      Logger.error(msgQuery.error.message);
      return <DefaultElement {...props} />;
    }

    const author = msgQuery.data?.message?.creator?.name;
    const message = msgQuery.data?.message?.body;
    const target = msgQuery.data?.message?.thread || undefined;

    if (!author || !message) {
      Logger.warn(`cannot render embedded message without author or body for message ${messageId}`);
      return <DefaultElement {...props} />;
    }

    // WARNING: If you focus this component before the internal RichText resolves slate may fail
    return (
      <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
        <_Container {...props.attributes} $selected={selected}>
          <EmbeddedMessage author={author} maxHeight={'100px'} target={target}>
            <React.Suspense fallback={<Spinner fullScreen />}>
              <RichText value={message} isPreview />
            </React.Suspense>
          </EmbeddedMessage>
        </_Container>
      </VoidInlineElement>
    );
  } catch (e) {
    return <DefaultElement {...props} />;
  }
}

const _Container = styled.div<{ $selected: boolean }>`
  filter: ${(props) => (props.$selected ? 'drop-shadow(0 0 4px blue)' : undefined)};
  padding: 4px;
  position: relative;
`;
