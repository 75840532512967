import { gql } from '@apollo/client';

export { type CF_ThreadEventReplies } from './___generated___/CF_ThreadEventReplies.gql.types';

export const CF_ThreadEventReplies_GQL = gql`
  fragment CF_ThreadEventReplies on IThreadEvent {
    id
    __typename
    thread {
      id
    }
    replyThread {
      id
      length
      messageCount
      unreadMessageCount
      typing {
        id
        name
      }
      participants {
        id
        name
      }
    }
  }
`;
