import { RTElementProp, RTExternalResourceRefElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';

import { useGetExternalResourceQuery } from '../../../../../hooks/query/useGetExternalResourceQuery';
import ResourceRef, { ResourceType } from '../../../../Resource/ResourceRef';
import Spinner from '../../../../Spinner';
import { IRTElementProps } from '../../../IRTElementProps';
import VoidInlineElement from '../../common/VoidInlineElement';

export interface IBaseExternalResourceRefElementProps extends IRTElementProps<RTExternalResourceRefElement> {
  canSee?: boolean;
  id?: string;
  loading?: boolean;
  name?: string;
  permalink?: string;
  selected?: boolean;
}

export function BaseExternalResourceRefElement(props: IBaseExternalResourceRefElementProps) {
  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <ResourceRef
        canSeeResource={props.canSee ?? false}
        editable={props.editable}
        resourceId={props.id}
        resourcePermalink={props.permalink}
        resourceTitle={props.name}
        resourceType={ResourceType.EXTERNAL_RESOURCE}
        selected={props.selected ?? false}
      />
      {props.loading && <Spinner size={'xxSmall'} />}
    </VoidInlineElement>
  );
}

export default function ExternalResourceRefElement(props: IRTElementProps<RTExternalResourceRefElement>) {
  const url = props.element[RTElementProp.EXTERNAL_RESOURCE_REF__URL];
  const query = useGetExternalResourceQuery({ url });
  const selected = useSelected();

  const externalResource = query.data?.link?.appObject;
  const stillProcessing = !query.data?.link?.processed;
  if (!externalResource) {
    return (
      <BaseExternalResourceRefElement
        {...props}
        canSee={true}
        loading={query.loading || stillProcessing}
        name={url}
        permalink={url}
        selected={selected}
      />
    );
  }

  return (
    <BaseExternalResourceRefElement
      {...props}
      canSee={true}
      id={externalResource.id}
      name={externalResource.name}
      permalink={externalResource.permalink}
      selected={selected}
    />
  );
}
