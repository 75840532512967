import { RTElement } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';

export default function DefaultElement(props: IRTElementProps<RTElement>) {
  return <Default {...props.attributes}>{props.children}</Default>;
}

const Default = styled.div``;
