import * as React from 'react';
import RBPopover, { PopoverProps } from 'react-bootstrap/Popover';
import styled from 'styled-components';

export const hideArrowModifier = {
  name: 'offset',
  options: {
    offset: [0, 0],
  },
};

const _Popover = styled(RBPopover)`
  background-color: ${({ theme }) => theme.color.background600};
  border-color: ${({ theme }) => theme.color.secondaryBorder};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  filter: drop-shadow(0 0 4px ${({ theme }) => theme.color.dropShadow});
  font-family: unset;
  font-size: unset;
  max-width: unset;

  .popover-arrow {
    display: none;
  }
`;

const _Header = styled(RBPopover.Header)`
  background-color: transparent;
  border-color: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 8px 12px;

  &::before {
    display: none !important;
  }
`;

const _Body = styled(RBPopover.Body)`
  color: ${({ theme }) => theme.color.color};
  padding: 8px 12px;
`;

export interface IBasePopoverProps extends PopoverProps {}

/**
 * `BasePopover` provides a static popover component for general use, without
 * any positioning. It should be used with an `Overlay` or `OverlayTrigger`.
 */
const BasePopover = React.forwardRef<HTMLDivElement, IBasePopoverProps>((props, ref) => {
  return <_Popover {...props} ref={ref} />;
});

BasePopover.displayName = 'BasePopover';

export default Object.assign(BasePopover, {
  Header: _Header,
  Body: _Body,
});
