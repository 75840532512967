import { gql } from '@apollo/client';
import { RTSerializer } from '@rmvw/x-common';
import * as React from 'react';
import { AccordionContext } from 'react-bootstrap';
import styled from 'styled-components';

import { AssistantContextFiltersInput, AssistantMessageState } from '../../../___generated___/globalTypes';
import { uppercaseText } from '../../../lib/css';
import Accordion, { useAccordionButton } from '../../Accordion';
import Alert from '../../Alert';
import { ProfileAvatar } from '../../Avatar';
import { ChevronRightIcon } from '../../Icon';
import Interactive from '../../Interactive';
import RichText from '../../rich-text/RichText';
import Spinner from '../../Spinner';
import ThreadResourceListItem from '../../ThreadDetails/ThreadResources/ThreadResourceListItem';
import Button from '../../ui/Button';
import AssistantMessage, { IAssistantMessageActionProps } from '../AssistantMessage';

import { CF_AssistantResponseMessage_AssistantTypeAssistantMessage } from './___generated___/AssistantResponseMessage.types';
import AssistantSourceSummaryChunk from './AssistantSourceSummaryChunk';

export type AssistantSourceSummaryChunk =
  CF_AssistantResponseMessage_AssistantTypeAssistantMessage['sourceSummaryChunks'][number];

const _SectionHeader = styled(Interactive)`
  align-items: center;
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  gap: 8px;

  ${uppercaseText()}
`;

const _SectionToggleIcon = styled(ChevronRightIcon)<{ $active: boolean }>`
  ${({ $active }) => ($active ? 'transform: rotate(90deg);' : undefined)}
  transition: transform 0.5s;
`;

interface ISectionToggleProps {
  eventKey: string;
}

function _SectionToggle({ children, eventKey }: React.PropsWithChildren<ISectionToggleProps>) {
  const { activeEventKey } = React.useContext(AccordionContext);

  const isActiveKey = !!(Array.isArray(activeEventKey)
    ? activeEventKey.find((k) => k === eventKey)
    : activeEventKey === eventKey);

  return (
    <_SectionHeader onClick={useAccordionButton(eventKey)}>
      <Button icon={<_SectionToggleIcon $active={isActiveKey} />} size={'xxSmall'} shape="circle" />
      <div>{children}</div>
    </_SectionHeader>
  );
}

const _Response = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
`;

const _Accordion = styled(Accordion)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const _SectionRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const _SourcesList = styled.ol`
  margin: 0;
`;

const _RelatedQuestionsList = styled.ul`
  margin: 0;
`;

const _RelatedQuestion = styled.a.attrs({ href: '#' })`
  display: block;
`;

export interface IAssistantResponseMessageProps
  extends IAssistantMessageActionProps,
    React.HTMLAttributes<HTMLDivElement> {
  message: CF_AssistantResponseMessage_AssistantTypeAssistantMessage;
  onCreateMessage?: (rtBody: string, args: { contextFilters: AssistantContextFiltersInput }) => void;
  onSourceClicked?: (sourceChunk: AssistantSourceSummaryChunk) => void;
  showQuestions?: boolean;
}

function AssistantResponseMessage({
  message,
  onCopyMessage,
  onCreateMessage,
  onShareMessage,
  onSourceClicked,
  showQuestions,
  ...divProps
}: IAssistantResponseMessageProps) {
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (showQuestions) {
      setActiveKeys((keys) => (keys.includes('questions') ? keys : [...keys, 'questions']));
    } else {
      setActiveKeys([]); // Collapse all sections if showQuestions is toggled off
    }
  }, [showQuestions]);

  if (message.state === AssistantMessageState.ERROR) {
    return (
      <AssistantMessage {...divProps}>
        <_Response>
          <Alert variant="danger">Something went wrong. Please try again.</Alert>
        </_Response>
      </AssistantMessage>
    );
  }

  const isEmptyResponse = !RTSerializer.toPlaintext(RTSerializer.deserialize(message.body));
  const actions =
    !isEmptyResponse && message.state === AssistantMessageState.COMPLETE
      ? { debugContext: message.debug, onCopyMessage, onShareMessage }
      : undefined;

  const questions = message.relatedQuestions.length ? message.relatedQuestions : null;
  const sources = message.sourceSummaryChunks.length ? message.sourceSummaryChunks : null;

  return (
    <AssistantMessage {...divProps} {...actions}>
      <_Response>
        {!isEmptyResponse ? (
          <RichText value={message.body} />
        ) : message.state === AssistantMessageState.COMPLETE ? (
          <>I&rsquo;m not sure about that one. Please try adjusting scope or asking a different question.</>
        ) : null}

        {sources || questions ? (
          <_Accordion
            activeKey={activeKeys}
            alwaysOpen
            onSelect={(key) => setActiveKeys(key instanceof Array ? key : key ? [key] : [])}
          >
            {sources && (
              <_SectionRow>
                <_SectionToggle eventKey="sources">Sources ({sources.length})</_SectionToggle>
                <Accordion.Collapse eventKey="sources">
                  <_SourcesList start={1}>
                    {message.sourceSummaryChunks.map((chunk) => (
                      <li key={chunk.id}>
                        <AssistantSourceSummaryChunk chunk={chunk} onClick={() => onSourceClicked?.(chunk)} />
                      </li>
                    ))}
                  </_SourcesList>
                </Accordion.Collapse>
              </_SectionRow>
            )}

            {questions && (
              <_SectionRow>
                <_SectionToggle eventKey="questions">Related Questions ({questions.length})</_SectionToggle>
                <Accordion.Collapse eventKey="questions">
                  <_RelatedQuestionsList>
                    {questions.map((question, idx) => (
                      <li key={idx}>
                        <_RelatedQuestion
                          onClick={(e) => {
                            onCreateMessage?.(question, {
                              contextFilters: {
                                scopeIds: message.contextFilters.scopedThreads.map(({ id }) => id), // Carry forward the context filters
                              },
                            });
                            e.preventDefault();
                          }}
                        >
                          <RichText value={question} />
                        </_RelatedQuestion>
                      </li>
                    ))}
                  </_RelatedQuestionsList>
                </Accordion.Collapse>
              </_SectionRow>
            )}
          </_Accordion>
        ) : null}

        {(message.state === AssistantMessageState.PROCESSING || message.state === AssistantMessageState.WAITING) && (
          <Spinner size="xxSmall" />
        )}
      </_Response>
    </AssistantMessage>
  );
}

const _AssistantResponseSourceSummaryChunkFragmentGQL = gql`
  ${ProfileAvatar.fragment}
  ${ThreadResourceListItem.fragment}
  fragment CF_AssistantSourceSummaryChunkFragment on SummaryChunk {
    ... on ThreadResourceSummaryChunk {
      id
      page
      resource {
        ...CF_ThreadResourceListItem
      }
    }

    ... on ThreadSummaryChunk {
      id
      firstEventTime
      permalink
      thread {
        id
        messageCount
        name
        permalink
        videoCount
        ...CF_ProfileAvatar

        ... on Meeting {
          media {
            id
            previewImage {
              id
              url(size: { width: 64, height: 64 })
            }
          }
        }
      }
    }
  }
`;

AssistantResponseMessage.fragment = gql`
  ${_AssistantResponseSourceSummaryChunkFragmentGQL}
  ${AssistantMessage.fragment}
  fragment CF_AssistantResponseMessage on IAssistantMessage {
    ...CF_AssistantMessage
    ... on AssistantTypeAssistantMessage {
      debug
      relatedQuestions
      sourceSummaryChunks {
        ...CF_AssistantSourceSummaryChunkFragment
      }
    }
  }
`;

export default AssistantResponseMessage;
