import * as React from 'react';
import styled from 'styled-components';

import PermissionScope from '../../../providers/PermissionScopeProvider';
import Alert from '../../Alert';
import Card from '../../Card';
import Spinner from '../../Spinner';
import { ThreadEventFragments } from '../___generated___/ThreadContent.gql.types';
import ThreadPreviewCard from '../ThreadPreviewCard/ThreadPreviewCard';
import ThreadPreviewCardHeader from '../ThreadPreviewCard/ThreadPreviewCardHeader';

const ThreadEvent = React.lazy(() => import('../ThreadEvent'));

const _CardBody = styled(Card.Body)`
  /* hack to compensate for 8px padding currently hard coded into ThreadItem */
  padding: 4px 0;
`;

export interface IThreadEventPreviewProps extends React.HTMLAttributes<HTMLElement> {
  event?: ThreadEventFragments | null;
  interactive?: boolean;
  loading?: boolean;
  selected?: boolean;
}

function ThreadEventPreview({ event, interactive, loading, selected, ...htmlProps }: IThreadEventPreviewProps) {
  let body: React.ReactNode;
  if (event) {
    body = (
      <PermissionScope scopeId={event.thread.id}>
        <React.Suspense fallback={<Spinner fullScreen />}>
          <ThreadEvent event={event} enableReplies={event.canReply} />
        </React.Suspense>
      </PermissionScope>
    );
  } else if (loading) {
    body = <Spinner fullScreen />;
  } else {
    body = (
      <Alert>
        <strong>Could not load preview</strong>: This can happen if the original item was deleted or if you do not have
        permission to view it.
      </Alert>
    );
  }

  return (
    <ThreadPreviewCard {...htmlProps} selected={selected}>
      {event?.thread.name && (
        <ThreadPreviewCardHeader
          interactive={interactive}
          name={event.thread.name}
          permalink={event.permalink} // Note we use the event permalink here, not the thread permalink
          profile={event.thread}
        />
      )}

      <_CardBody>{body}</_CardBody>
    </ThreadPreviewCard>
  );
}

export default ThreadEventPreview;
