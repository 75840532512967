import * as React from 'react';
import styled from 'styled-components';

import { truncateText } from '../../lib/css';
import Tooltip from '../Tooltip';
import Button, { IButtonProps } from '../ui/Button';

import PaneHeaderThreadContext from './ThreadContext';
import ThreadOriginBreadcrumbs, { BreadcrumbList } from './ThreadOriginBreadcrumbs';

export const PaneHeaderSizes = {
  small: 600,
  large: 1200,
};

//
// PaneHeader.Bar
//

const _PaneHeaderBar = styled.div<{ $centerContent?: boolean }>`
  align-items: ${({ $centerContent }) => ($centerContent ? 'center' : 'end')};
  background-color: ${({ theme }) => theme.color.background800};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  gap: 8px;
  padding: ${({ $centerContent }) => ($centerContent ? '26px 24px' : '8px 24px 24px')};

  &:hover ${BreadcrumbList}, &:focus ${BreadcrumbList} {
    opacity: 1;
  }
`;

interface IPaneHeaderBarProps extends React.HTMLAttributes<HTMLDivElement> {
  centerContent?: boolean;
}

const PaneHeaderBar = React.forwardRef(
  (
    { centerContent, children, ...props }: IPaneHeaderBarProps & { children: React.ReactNode },
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <_PaneHeaderBar $centerContent={centerContent} ref={ref} {...props}>
        {children}
      </_PaneHeaderBar>
    );
  }
);

PaneHeaderBar.displayName = 'PaneHeader.Bar';

//
// PaneHeader.ControlButton
//

interface IPaneHeaderControlButtonProps extends Omit<IButtonProps, 'shape'> {
  active?: boolean;
  label: string;
}

function PaneHeaderControlButton({ active, label, ...props }: IPaneHeaderControlButtonProps) {
  const size = props.size ?? 'medium';
  return (
    <Tooltip title={label}>
      <Button {...props} aria-label={label} shape="circle" size={size} type={active ? 'primary' : 'ghost'} />
    </Tooltip>
  );
}

//
// PaneHeader
//

const _Subject = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  height: 28px;
  max-width: 100%;
`;

const _AvatarContainer = styled.div`
  align-items: center;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
`;

const _Name = styled.div`
  color: ${({ theme }) => theme.color.color};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  gap: 4px;
  margin: 0;
  max-width: 100%;
  min-width: 0;
  ${truncateText()}
`;

const _ContextLine = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  height: 20px;
`;

const _Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
  min-width: 0;
`;

const _Children = styled.div`
  display: flex;
`;

export interface IPageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  avatar?: JSX.Element;
  context?: JSX.Element | string;
  name: JSX.Element | string;
}

const PaneHeader = React.forwardRef(
  ({ avatar, context, name, ...props }: React.PropsWithChildren<IPageHeaderProps>, ref: React.Ref<HTMLDivElement>) => {
    return (
      <PaneHeaderBar centerContent={!context} ref={ref} {...props}>
        <_Main>
          {context && <_ContextLine>{context}</_ContextLine>}
          <_Subject>
            {avatar && <_AvatarContainer>{avatar}</_AvatarContainer>}
            <_Name>{name}</_Name>
          </_Subject>
        </_Main>
        <_Children>{props.children}</_Children>
      </PaneHeaderBar>
    );
  }
);

PaneHeader.displayName = 'PaneHeader';

export default Object.assign(PaneHeader, {
  Bar: PaneHeaderBar,
  ControlButton: PaneHeaderControlButton,
  ThreadContext: PaneHeaderThreadContext,
  OriginBreadcrumbs: ThreadOriginBreadcrumbs,
});
