import { RTBlockquoteElement } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';

const _Blockquote = styled.blockquote`
  font-size: ${({ theme }) => theme.fontSize.p};
  margin: 0;
  padding: 0 0 0 8px;
`;

export default function BlockquoteElement(props: IRTElementProps<RTBlockquoteElement>) {
  return <_Blockquote {...props.attributes}>{props.children}</_Blockquote>;
}
