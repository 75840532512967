import { LegacyPalette } from '@rmvw/c-common';
import * as React from 'react';
import RBDropdownItem, { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import styled from 'styled-components';

export interface IDropdownItemProps extends DropdownItemProps {
  danger?: boolean;
}

const _Item = styled(RBDropdownItem)<{ $danger?: boolean }>`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.color.color};
  display: flex;
  gap: 8px;
  margin: 2px 0;
  padding: 6px 8px;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.color.background400};
    color: ${({ theme }) => theme.color.color};
  }

  &.active,
  &:active {
    background-color: ${({ theme }) => theme.color.variant.primary.color};
    color: ${LegacyPalette.white};
  }

  ${({ $danger, theme }) => {
    const isLightTheme = theme.spec.scheme === 'light';

    if ($danger) {
      return `
        color: ${isLightTheme ? theme.color.variant.danger.text : LegacyPalette.red40};

        &:focus,
        &:hover {
          background-color: ${theme.color.variant.danger.background};
          color: ${theme.color.variant.danger.text};
        }

        &.active,
        &:active {
          color: ${LegacyPalette.white};
          background-color: ${theme.color.variant.danger.color};
        }
      `;
    }
  }}

  &.disabled,
  &:disabled {
    background-color: initial;
    color: ${({ theme }) => theme.color.secondaryColor};
    pointer-events: none;
  }
`;

const DropdownItem = React.forwardRef<HTMLElement, IDropdownItemProps>(({ danger, ...props }, ref) => (
  <_Item {...props} $danger={danger} ref={ref} />
));

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
