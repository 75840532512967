import { ApolloCache, ApolloClient, Reference, StoreObject } from '@apollo/client';

export function deleteFromCache(cache: ApolloCache<object>, object: StoreObject | Reference | null) {
  if (!object) {
    return;
  }
  // It seems `evict(...)` doesn't propagate to referencing queries, so we need to call gc manually:
  // https://www.apollographql.com/docs/react/caching/garbage-collection/
  cache.evict({ id: cache.identify(object) });
  cache.gc();
}

export function evictById(client: ApolloClient<object>, id: string | null) {
  if (!id) {
    return;
  }
  const cache = client.cache;
  const allKeys = cache.extract(); // Get all cache entries
  for (const key in allKeys) {
    const entry = (<any>allKeys)[key];
    if (entry && entry.id === id) {
      cache.evict({ id: key });
    }
  }
  cache.gc(); // garbage collect any references
}
