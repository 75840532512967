import * as React from 'react';
import styled from 'styled-components';

const _UnreadIndicator = styled.div<{ color?: string; size?: number }>`
  aspect-ratio: 1;
  background-color: ${({ color, theme }) => (color ? color : theme.color.secondaryEmphasisColor)};
  border-radius: 50%;
  height: ${({ size }) => (size ? `${size}px` : 'auto')};
  min-height: ${({ size }) => (size ? `${size}px` : undefined)};
`;

export interface IUnreadIndicatorProps {
  color?: string;
  size?: number;
}

function UnreadIndicator(props: IUnreadIndicatorProps) {
  return <_UnreadIndicator color={props.color} size={props.size} />;
}

export default UnreadIndicator;
