import { gql } from '@apollo/client';
import { Paths, addTabToUrl } from '@rmvw/x-common';
import { differenceInSeconds, parseISO } from 'date-fns';
import formatDuration from 'format-duration';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useLoggedInAccount } from '../../hooks/useLoggedInAccount';
import { truncateText } from '../../lib/css';
import { useAppMeetingState } from '../../providers/AppMeetingStateProvider';
import { ProfileAvatar } from '../Avatar';
import Card from '../Card';
import { ChevronRightDoubleIcon } from '../Icon';
import LivePreview from '../meeting/LivePreview';
import MeetingMediaPreview, { IMeetingMediaPreviewRef } from '../meeting/MeetingMediaPreview';
import OverflowMask from '../OverflowMask';
import PaneHeader from '../PaneHeader';
import ThreadPreviewCard from '../thread/ThreadPreviewCard/ThreadPreviewCard';
import ThreadPreviewCardHeader from '../thread/ThreadPreviewCard/ThreadPreviewCardHeader';
import ThreadBadgeFooter from '../ThreadBadgeFooter';

import {
  CF_VideoChatPreviewCard,
  CF_VideoChatPreviewCard_PreviewImage,
} from './___generated___/VideoChatPreviewCard.types';

const _CardBody = styled(Card.Body)`
  flex-flow: row wrap;
  gap: 8px;
  overflow: hidden;
`;

const _NoRecording = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const _MediaContainer = styled.div`
  max-width: ${({ theme }) => theme.dimension.mediaContainer.maxWidth};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: unset;
  }
`;

const _SummaryContainer = styled.div`
  flex: 1;
  min-width: min(100%, calc(${({ theme }) => theme.dimension.mediaContainer.maxWidth} * 0.66));
`;

const _SummaryInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const _SummaryHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: bold;
`;

const _SummaryDetails = styled.div`
  display: grid;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  grid-template-columns: 1em 4px auto 8px min-content;
`;

const _SummaryDetailCaret = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.emphasisColor};
  display: flex;
  grid-column: 1;
  transition: opacity 0.2s;
`;

const _SummaryDetailRow = styled(Link)<{ $highlighted: boolean; $row: number }>`
  color: ${({ $highlighted, theme }) => ($highlighted ? theme.color.emphasisColor : theme.color.color)};
  display: contents;

  & > * {
    grid-row: ${({ $row }) => $row};
  }

  ${_SummaryDetailCaret} {
    display: ${({ $highlighted }) => ($highlighted ? 'flex' : 'none')};
    opacity: ${({ $highlighted }) => ($highlighted ? 1 : 0)};
  }
`;

const _SummaryDetailItem = styled.div`
  align-items: center;
  grid-column: 3;
  min-width: 0;
  ${truncateText()}
`;

const _SummaryDetailTimestamp = styled.div`
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  grid-column: 5;
  text-align: right;
  white-space: nowrap;
`;

export interface IVideoChatPreviewCardProps extends React.HTMLAttributes<HTMLElement> {
  autoPlay?: boolean;
  interactive?: boolean;
  meeting: CF_VideoChatPreviewCard & CF_VideoChatPreviewCard_PreviewImage;
  offsetSeconds?: number;
  selected?: boolean;
}

function VideoChatPreviewCard({
  autoPlay,
  offsetSeconds,
  interactive,
  meeting,
  selected,
  ...htmlProps
}: IVideoChatPreviewCardProps) {
  const account = useLoggedInAccount();
  const navigate = useNavigate();
  const theme = useTheme();
  const { showMeeting } = useAppMeetingState();

  const [playbackPosition, setPlaybackPosition] = React.useState(0);
  const [playing, setPlaying] = React.useState(!!autoPlay);

  const meetingMediaPreviewRef = React.useRef<IMeetingMediaPreviewRef>(null);

  const isLiveMeeting = !!meeting.isActive;

  let media: JSX.Element;
  if (isLiveMeeting) {
    media = <LivePreview meeting={meeting} />;
  } else if (meeting.media) {
    media = (
      <MeetingMediaPreview
        media={meeting.media}
        offsetSeconds={offsetSeconds}
        onGoToMeeting={(offset) => navigate(Paths.MEETING(meeting.id, { offsetSec: offset }))}
        onPlayerProgress={setPlaybackPosition}
        playing={playing}
        startTime={parseISO(meeting.startTime)}
        endTime={parseISO(meeting.endTime)}
        ref={meetingMediaPreviewRef}
      />
    );
  } else {
    media = <_NoRecording>No video recording.</_NoRecording>; // There's no preview to render!
  }

  // Determine which summary text to show
  const currentPositionSeconds = isLiveMeeting
    ? differenceInSeconds(new Date(), parseISO(meeting.startTime))
    : playing
    ? playbackPosition
    : offsetSeconds;
  const summaryEventGroups = (meeting.summaryEventGroupConnections.edges?.filterNullish() ?? []).filter(
    ({ node }) => node?.title && node.meetingOffsetSeconds !== null
  );
  const currentSummaryEventGroup = currentPositionSeconds
    ? [...summaryEventGroups].reverse().find(({ node }) => currentPositionSeconds >= (node?.meetingOffsetSeconds ?? 0))
    : null;
  const summaryDetail = summaryEventGroups.length
    ? summaryEventGroups.map(
        ({ node }, i) =>
          node?.title && (
            <_SummaryDetailRow
              $highlighted={node.id === currentSummaryEventGroup?.node?.id}
              $row={i + 1}
              key={node.id}
              onClick={(e) => {
                e.preventDefault();
                if (!isLiveMeeting) {
                  // Intercept click events to prevent navigation when playback is active
                  setPlaying(true);
                  meetingMediaPreviewRef.current?.seekTo(node.meetingOffsetSeconds ?? 0);
                  meetingMediaPreviewRef.current?.unMute();
                } else if (meeting.sourceMeetingSeries?.code) {
                  showMeeting({ seriesCode: meeting.sourceMeetingSeries.code });
                }
              }}
              // Include permalink for accessibility / copy-paste links / etc
              to={Paths.MEETING(meeting.id, { offsetSec: node.meetingOffsetSeconds ?? 0 })}
            >
              <_SummaryDetailCaret>
                <ChevronRightDoubleIcon strokeWidth={4} />
              </_SummaryDetailCaret>
              <_SummaryDetailItem title={node.title}>{node.title}</_SummaryDetailItem>
              <_SummaryDetailTimestamp>
                {formatDuration((node.meetingOffsetSeconds ?? 0) * 1000)}
              </_SummaryDetailTimestamp>
            </_SummaryDetailRow>
          )
      )
    : null;

  return (
    <ThreadPreviewCard {...htmlProps} selected={selected}>
      <ThreadPreviewCardHeader
        breadcrumbs={meeting}
        interactive={interactive}
        name={meeting.name}
        permalink={isLiveMeeting ? meeting.permalink : Paths.MEETING(meeting.id, { offsetSec: offsetSeconds })}
        profile={meeting}
      />

      <_CardBody>
        <_MediaContainer onMouseEnter={autoPlay ? undefined : () => setPlaying(true)}>{media}</_MediaContainer>

        {summaryDetail && (
          <_SummaryContainer>
            <OverflowMask expandable maxHeight={theme.dimension.mediaContainer.maxHeight}>
              <_SummaryInnerContainer>
                <_SummaryHeader>Outline</_SummaryHeader>
                <_SummaryDetails>{summaryDetail}</_SummaryDetails>
              </_SummaryInnerContainer>
            </OverflowMask>
          </_SummaryContainer>
        )}
      </_CardBody>
      <ThreadBadgeFooter
        messageCount={meeting.replyThread?.messageCount}
        participantCount={meeting.meetingParticipants?.length}
        targetUrl={interactive ? addTabToUrl(meeting.permalink, 'chat') : undefined}
        unreadMessageCount={meeting.replyThread?.unreadMessageCount}
      />
    </ThreadPreviewCard>
  );
}

VideoChatPreviewCard.CF_VideoChatPreviewCard = gql`
  ${LivePreview.fragment}
  ${PaneHeader.ThreadContext.fragment}
  ${ProfileAvatar.fragment}

  fragment CF_VideoChatPreviewCard on Meeting {
    id
    abstract
    isActive
    permalink
    media {
      id
      url
    }
    name
    startTime
    endTime

    meetingParticipants {
      id
    }

    # Fixed page size of 100 chapters/chunks for now (100 * 5 min = ~8 hour meeting)
    summaryEventGroupConnections(page: { size: 100 }) {
      edges {
        node {
          id
          meetingOffsetSeconds
          title
        }
      }
    }

    replyThread {
      id
      messageCount
      unreadMessageCount
    }

    sourceMeetingSeries {
      id
      code
    }

    ...CF_LivePreviewMeeting
    ...CF_PaneHeaderThreadContext
    ...CF_ProfileAvatar
  }
`;

VideoChatPreviewCard.CF_VideoChatPreviewCard_PreviewImage = gql`
  fragment CF_VideoChatPreviewCard_PreviewImage on Meeting {
    id
    media {
      id
      previewImage {
        id
        url
      }
    }
  }
`;

export default VideoChatPreviewCard;
