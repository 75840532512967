import { RTElementProp, RTLinkElement, isInternalLink } from '@rmvw/x-common';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Tooltip from '../../../../Tooltip';
import { IRTElementProps } from '../../../IRTElementProps';
import DefaultElement from '../../DefaultElement/DefaultElement';

const _Link = styled.a`
  &:hover {
    text-decoration: underline;
  }

  text-decoration: none;
`;

export interface IBaseLinkElementProps extends IRTElementProps<RTLinkElement> {
  editable?: boolean;
}

export function BaseLinkElement(props: IBaseLinkElementProps) {
  const navigate = useNavigate();

  try {
    const href = props.element[RTElementProp.LINK__HREF];
    if (!href) {
      return props.children;
    }

    // Determine if whether link is an internal link
    const url = new URL(href, location.href);
    return isInternalLink(href) && !props.editable ? (
      <span {...props.attributes}>
        <Tooltip title={url.toString()}>
          <_Link
            href={url.toString()} // Preserve complete URL for copy/paste in different apps
            onClick={(e) => {
              if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
                // Allow user to open link in new tab/window/etc. using default browser behavior
                return;
              }

              // Otherwise intercept the click and navigate to internal link
              navigate(url.pathname + url.search);
              e.preventDefault();
            }}
          >
            {props.children}
          </_Link>
        </Tooltip>
      </span>
    ) : (
      <span {...props.attributes}>
        <Tooltip title={url.toString()}>
          <_Link href={url.toString()} target="_blank">
            {props.children}
          </_Link>
        </Tooltip>
      </span>
    );
  } catch (e) {
    // Not a valid URL...do not linkify
    return <DefaultElement {...props} />;
  }
}

export default function LinkElement(props: IRTElementProps<RTLinkElement>) {
  return <BaseLinkElement {...props} />;
}
