import { gql, useQuery } from '@apollo/client';
import { RTDiscussionRefElement, RTElementProp } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';

import ResourceRef, { ResourceType } from '../../../../Resource/ResourceRef';
import { IRTElementProps } from '../../../IRTElementProps';
import VoidInlineElement from '../../common/VoidInlineElement';

import {
  BATCHED__DiscussionRefElement_DiscussionQuery,
  BATCHED__DiscussionRefElement_DiscussionQueryVariables,
} from './___generated___/DiscussionRefElement.types';

export interface IBaseDiscussionRefElementProps extends IRTElementProps<RTDiscussionRefElement> {
  canSee?: boolean;
  name?: string;
  permalink?: string;
  selected?: boolean;
}

export function BaseDiscussionRefElement(props: IBaseDiscussionRefElementProps) {
  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <ResourceRef
        canSeeResource={props.canSee ?? false}
        editable={props.editable}
        resourceId={props.element[RTElementProp.DISCUSSION_REF__DISCUSSION_ID]}
        resourcePermalink={props.permalink}
        resourceTitle={props.name}
        resourceType={ResourceType.DISCUSSION}
        selected={props.selected ?? false}
        threadEventId={props.element[RTElementProp.DISCUSSION_REF__EVENT_ID]}
      />
    </VoidInlineElement>
  );
}

const DiscussionQuery = gql`
  # Batched query
  query BATCHED__DiscussionRefElement_DiscussionQuery($id: ID!) {
    discussion(id: $id) {
      id
      name
      permalink
    }
  }
`;

export default function DiscussionRefElement(props: IRTElementProps<RTDiscussionRefElement>) {
  const selected = useSelected();
  const discussionQuery = useQuery<
    BATCHED__DiscussionRefElement_DiscussionQuery,
    BATCHED__DiscussionRefElement_DiscussionQueryVariables
  >(DiscussionQuery, {
    variables: { id: props.element[RTElementProp.DISCUSSION_REF__DISCUSSION_ID] ?? '' },
    skip: !props.element[RTElementProp.DISCUSSION_REF__DISCUSSION_ID],
  });

  const canSeeDiscussion = discussionQuery.loading || !!discussionQuery.data?.discussion?.id;

  return (
    <BaseDiscussionRefElement
      {...props}
      canSee={canSeeDiscussion}
      name={discussionQuery.data?.discussion?.name}
      permalink={discussionQuery.data?.discussion?.permalink}
      selected={selected}
    />
  );
}
