import { RTElementProp, RTThreadEventCardElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import { ThreadEventFragments } from '../../../thread/___generated___/ThreadContent.gql.types';
import ThreadEventPreview from '../../../thread/ThreadEventPreview';
import { IRTElementProps } from '../../IRTElementProps';
import VoidBlockElement from '../common/VoidBlockElement';

import { useThreadEventPreviewQuery } from './useThreadEventPreviewQuery';

const _ThreadEventPreview = styled(ThreadEventPreview)<{ $editable?: boolean }>`
  /* disable mouse events when element is in edit mode */
  pointer-events: ${({ $editable }) => ($editable ? 'none' : 'auto')};
`;

export interface IBaseThreadEventCardElementProps extends IRTElementProps<RTThreadEventCardElement> {
  event?: ThreadEventFragments | null;
  loading?: boolean;
  selected?: boolean;
}

function BaseThreadEventCardElement(props: IBaseThreadEventCardElementProps) {
  return (
    <VoidBlockElement attributes={props.attributes} slateChildren={props.children}>
      <_ThreadEventPreview
        $editable={props.editable}
        event={props.event}
        interactive={!props.editable}
        loading={props.loading}
        selected={props.selected}
      />
    </VoidBlockElement>
  );
}

export default function ThreadEventCardElement(props: IRTElementProps<RTThreadEventCardElement>) {
  const selected = useSelected();
  const threadEventId = props.element[RTElementProp.THREAD_EVENT_CARD__EVENT_ID];
  const { loading, data } = useThreadEventPreviewQuery({ input: threadEventId ?? '' }, { skip: !threadEventId });
  return <BaseThreadEventCardElement {...props} event={data?.threadEvent} loading={loading} selected={selected} />;
}
