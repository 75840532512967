import * as React from 'react';

interface IMeetingState {
  options?: { inviteMembers?: boolean };
  seriesCode: string | null;
}

export interface IAppMeetingStateManager {
  hideMeeting: () => void;
  meetingState: IMeetingState;
  showMeeting: (args: { seriesCode: string; options?: IMeetingState['options'] }) => void;
}

const _throwNotInitialized = () => {
  throw new Error('AppMeetingStateContext not initialized');
};

export const defaultContext: IAppMeetingStateManager = {
  hideMeeting: _throwNotInitialized,
  meetingState: { seriesCode: null },
  showMeeting: _throwNotInitialized,
};

export const AppMeetingStateContext = React.createContext<IAppMeetingStateManager>(defaultContext);

export default function AppMeetingStateProvider(props: React.PropsWithChildren<unknown>) {
  const [meetingState, setMeetingState] = React.useState<IMeetingState>({ seriesCode: null });

  const showMeeting = React.useCallback(
    ({ options, seriesCode }: Parameters<IAppMeetingStateManager['showMeeting']>[0]) => {
      setMeetingState({ seriesCode, options }); // Note we deliberately set an object here to force re-render in case user clicks on same meeting again
    },
    []
  );

  const hideMeeting = React.useCallback(() => {
    setMeetingState({ seriesCode: null });
  }, []);

  const appMeetingState = React.useMemo<IAppMeetingStateManager>(
    () => ({
      hideMeeting,
      meetingState,
      showMeeting,
    }),
    [hideMeeting, meetingState, showMeeting]
  );

  return <AppMeetingStateContext.Provider value={appMeetingState}>{props.children}</AppMeetingStateContext.Provider>;
}

export const useAppMeetingState = (): IAppMeetingStateManager => {
  return React.useContext(AppMeetingStateContext);
};
