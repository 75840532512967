import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { ActivityStatus } from '../../___generated___/globalTypes';
import Logger from '../../lib/observability/Logger';
import { getStatusColor } from '../../lib/Status';
import { Avatar, ProfileAvatarById } from '../Avatar';
import AvatarShell from '../AvatarShell';
import AccountHoverCard from '../HoverCard/AccountHoverCard';

import { CQ_LoadedLiveAvatar, CQ_LoadedLiveAvatarVariables } from './___generated___/LiveAvatar.types';

const AVATAR_SIZE = 40;

const _Container = styled.div``;

const _Avatar = styled(Avatar)`
  margin-left: -2px;
  margin-top: -2px;
  position: absolute;
`;

interface IDataProps {
  profileImageUrl?: string;
  status?: ActivityStatus;
  name?: string;
  isMe: boolean;
  accountId?: string;
}

interface IOptionsProps {
  className?: string;
  size?: number;
  /**
   * Desaturate avatar
   */
  desaturate?: boolean;
}

export interface ILiveAvatarProps extends IDataProps, IOptionsProps {}

export default function LiveAvatar(props: ILiveAvatarProps) {
  const theme = useTheme();

  const _renderPreview = () => {
    // oof, no good way to pass arguments down to sub-fragments
    // TODO: figure out good way to set avatar size in parent query
    return props.accountId && !props.isMe ? (
      <AccountHoverCard id={props.accountId}>
        <ProfileAvatarById id={props.accountId} shape="circle" />
      </AccountHoverCard>
    ) : (
      <_Avatar alt={props.name} src={props.profileImageUrl} size={props.size} />
    );
  };

  const preview = _renderPreview();
  return preview ? (
    <_Container className={props.className}>
      <AvatarShell
        color={getStatusColor(theme, props.status)}
        size={props.size}
        thickness={props.status !== ActivityStatus.UNKNOWN ? 2 : 1}
      >
        <_Desaturate enabled={props.desaturate}>{preview}</_Desaturate>
      </AvatarShell>
    </_Container>
  ) : null;
}

LiveAvatar.defaultSize = AVATAR_SIZE;

/**
 * Note that $avatarSize is global & transitive. It has to be set it in the
 * query that this fragment ultimately rolls up to. Unfortunately there is no
 * reliable support for passing arguments to fragments so this is what we're left with.
 *
 * Note that unfortunately you cannot inline values using using usual
 * template literal syntax i.e. ${AVATAR_SIZE} because Apollo's code-gen does not support that.
 */
LiveAvatar.fragment = gql`
  fragment CF_LiveAvatar on Account {
    id
    isMe
    name
    activityStatus
    profileImage {
      id
      url(size: { width: 256, height: 256 })
    }
  }
`;

const _Desaturate = styled.div<{ enabled?: boolean }>`
  filter: ${(props) => (props.enabled ? 'grayscale(100%)' : undefined)};
  height: 100%;
  width: 100%;
`;

export const LoadedLiveAvatarQuery = gql`
  ${LiveAvatar.fragment}
  query CQ_LoadedLiveAvatar($accountId: ID!) {
    account(id: $accountId) {
      ...CF_LiveAvatar
    }
  }
`;

export const LoadedLiveAvatar = graphql<
  IOptionsProps & CQ_LoadedLiveAvatarVariables,
  CQ_LoadedLiveAvatar,
  CQ_LoadedLiveAvatarVariables
>(LoadedLiveAvatarQuery, {
  alias: 'LoadedLiveAvatar',
  options: (props) => {
    return {
      variables: {
        accountId: props.accountId,
      },
    };
  },
})(function (props) {
  if (props.data?.loading) {
    return null;
  }
  if (props.data?.error) {
    Logger.error(props.data?.error, props.data?.error.message);
    return null;
  }
  const account = props.data?.account;
  if (!account) {
    Logger.error('empty data');
    return null;
  }
  const newProps: ILiveAvatarProps = {
    ...props,
    profileImageUrl: account.profileImage?.url || undefined,
    name: account.name || undefined,
    status: account.activityStatus || undefined,
    isMe: account.isMe || false,
  };
  return LiveAvatar(newProps);
});
