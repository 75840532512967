import { RTElementProp, RTTableElement } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';

const _Container = styled.div<{ $layoutOnly: boolean }>`
  border: ${({ $layoutOnly, theme }) => (!$layoutOnly ? `1px solid ${theme.color.border}` : undefined)};
  border-radius: ${({ $layoutOnly, theme }) => (!$layoutOnly ? theme.borderRadius.medium : undefined)};
  display: inline-block;
  overflow: hidden;
`;

const _Table = styled.table<{ $layoutOnly: boolean }>`
  background-color: ${({ $layoutOnly, theme }) => (!$layoutOnly ? theme.color.background900 : undefined)};
  border-collapse: collapse;
  border-spacing: 0;
  font-size: ${({ $layoutOnly, theme }) => (!$layoutOnly ? theme.fontSize.small : undefined)};
  font-variant-numeric: tabular-nums;

  & thead {
    background-color: ${({ $layoutOnly, theme }) => (!$layoutOnly ? theme.color.background300 : undefined)};
  }

  & tbody tr:hover {
    background-color: ${({ $layoutOnly, theme }) => (!$layoutOnly ? theme.color.background600 : undefined)};
  }

  & td,
  th {
    border: ${({ $layoutOnly, theme }) => (!$layoutOnly ? `1px solid ${theme.color.border}` : undefined)};
    padding: 4px 8px;
    text-align: left;
    vertical-align: top;
  }

  & th {
    font-weight: ${({ $layoutOnly, theme }) => (!$layoutOnly ? theme.fontWeight.bold : undefined)};
  }

  & th:first-child,
  & td:first-child {
    border-left: none;
  }

  & th:last-child,
  & td:last-child {
    border-right: none;
  }

  & tr:first-child th,
  & tr:first-child td {
    border-top: none;
  }

  & tr:last-child td,
  & tr:last-child th {
    border-bottom: none;
  }

  & > tr:only-child > td:only-child,
  & > tbody:only-child > tr:only-child > td:only-child {
    /* Remove padding on cell if there is only one cell in layout-only table */
    padding: ${({ $layoutOnly }) => (!$layoutOnly ? undefined : '0px')};
  }
`;

export default function TableElement(props: IRTElementProps<RTTableElement>) {
  return (
    <_Container $layoutOnly={!!props.element[RTElementProp.TABLE__LAYOUT_ONLY]} {...props.attributes}>
      <_Table $layoutOnly={!!props.element[RTElementProp.TABLE__LAYOUT_ONLY]}>{props.children}</_Table>
    </_Container>
  );
}
