import { LegacyPalette } from '@rmvw/c-common';
import { differenceInSeconds } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

import { hexColorWithAlpha } from '../../lib/css';
import Duration from '../Duration';

export const PreviewMeetingDuration = styled(MeetingDuration)`
  background-color: ${hexColorWithAlpha(LegacyPalette.black, 0.4)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  bottom: 8px;
  color: ${LegacyPalette.white};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  left: 8px;
  padding: 0 2px;
  position: absolute;
`;

export interface IMeetingDurationProps extends React.HTMLAttributes<HTMLDivElement> {
  end?: Date;
  offsetSeconds?: number;
  start: Date;
}

function MeetingDuration({ start, end, offsetSeconds, ...props }: IMeetingDurationProps) {
  const [_end, _setEnd] = React.useState(end || new Date());

  React.useEffect(() => {
    if (end) {
      _setEnd(end);
      return;
    }

    const interval = setInterval(() => _setEnd(new Date()), 1_000);
    return () => clearInterval(interval);
  }, [end, start]);

  return (
    <div {...props}>
      {offsetSeconds && (
        <>
          <Duration seconds={offsetSeconds} />
          &nbsp;/&nbsp;
        </>
      )}
      <Duration seconds={differenceInSeconds(_end, start)} />
    </div>
  );
}

export default MeetingDuration;
