import { RTElementProp, RTFootnoteElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';
import VoidInlineElement from '../common/VoidInlineElement';

const _Footnote = styled(VoidInlineElement)<{ $selected: boolean }>`
  background-color: ${({ $selected, theme }) => $selected && theme.color.textSelectionBackground};
  color: ${({ theme }) => theme.color.secondaryColor};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  line-height: 1;
  margin: 1px;
  transition: color 0.25s ease-in-out;
  vertical-align: text-top;

  ::before {
    content: '[';
    margin-right: 1px;
  }

  ::after {
    content: ']';
    margin-left: 1px;
  }
`;

export default function FootnoteElement(props: IRTElementProps<RTFootnoteElement>) {
  const selected = useSelected();

  return (
    <_Footnote $selected={selected} attributes={props.attributes} slateChildren={props.children}>
      {props.element[RTElementProp.FOOTNOTE__MARK]}
    </_Footnote>
  );
}
