import { CourbetDarkColorTheme, CourbetLightColorTheme, LegacyPalette, ThemeName } from '@rmvw/c-common';
import { DefaultTheme } from 'styled-components';

import { IS_DESKTOP_APP, IS_MAC } from '../../env';
import { hexColorWithAlpha } from '../../lib/css';

export interface IThemeSpec {
  internalOnly?: boolean;
  label: string;
  name: ThemeName;
  scheme: 'light' | 'dark';
}

export const ThemeSpecs: { [K in ThemeName]: IThemeSpec & { name: K } } = {
  dark: { name: 'dark', label: 'Dark', scheme: 'dark' },
  light: { name: 'light', label: 'Light', scheme: 'light' },
};

export function isValidThemeName(themeName?: string | null): themeName is ThemeName {
  return !!themeName && Object.keys(ThemeSpecs).includes(themeName);
}

export const shadow = {
  normal: `0px 0px 8px 0px ${hexColorWithAlpha(LegacyPalette.gray60, 0.12)}`,
  active: `0px 0px 8px 0px ${hexColorWithAlpha(LegacyPalette.gray60, 0.24)}`,
  hover: `0px 0px 8px 0px ${hexColorWithAlpha(LegacyPalette.gray60, 0.24)}`,
  focus: `0px 0px 12px 0px ${hexColorWithAlpha(LegacyPalette.gray60, 0.48)}`,
};

export const CanonicalSizePx = {
  xxSmall: 16,
  xSmall: 20,
  small: 24,
  medium: 28,
  large: 32,
  xLarge: 40,
};

export type CanonicalSize = keyof typeof CanonicalSizePx;

export const borderRadius = {
  xSmall: '2px',
  small: '4px',
  medium: '8px',
  large: '16px',
  xLarge: '32px',
};

export const breakpoint = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
};

export const dimension = {
  header: {
    // Note: these dimensions are for OS X native app. The future Windows
    // native app will need different settings.
    avatarSizePx: IS_DESKTOP_APP && IS_MAC ? 24 : 32,
    height: IS_DESKTOP_APP && IS_MAC ? '44px' : '56px',
    padding: '0 16px',
    nativeSpacer: IS_DESKTOP_APP && IS_MAC ? '58px' : '0',
  },

  inboxThread: {
    height: {
      desktop: '44px',
      tablet: '56px',
      mobile: '56px',
    },
  },

  mainSidebar: {
    width: '80px',
  },

  sidebarMenu: {
    maxWidth: '600px',
    minWidth: '250px',
    width: '350px',
  },

  mediaContainer: {
    maxWidth: '294px',
    maxHeight: '165px', // 16:9 aspect ratio
  },

  mobileFooter: {
    height: '60px',
  },

  teamPane: {
    headerHeight: '138px',
  },

  threadPreviewCard: {
    maxWidth: '600px',
  },
};

export const fontSize = {
  // This is the base font size for the page.
  root: '14px',

  // These are the canonical font sizes used in the app.
  xxxSmall: '0.714rem', // 10px @ 14px root
  xxSmall: '0.786rem', // 11px @ 14px root
  xSmall: '0.857rem', // 12px @ 14px root
  small: '0.929rem', // 13px @ 14px root
  normal: '1rem', // 14px @ 14px root
  medium: '1.143rem', // 16px @ 14px root
  large: '1.286rem', // 18px @ 14px root
  xLarge: '1.429rem', // 20px @ 14px root
  xxLarge: '1.7141rem', // 24px @ 14px root

  /**
   * @deprecated use `fontSize.normal` instead
   */
  p: '1rem',

  /**
   * @deprecated
   */
  h6: '1.125rem',

  /**
   * @deprecated
   */
  h5: '1.266rem',

  /**
   * @deprecated
   */
  h4: '1.424rem',

  /**
   * @deprecated
   */
  h3: '1.602rem',

  /**
   * @deprecated
   */
  h2: '1.802rem',

  /**
   * @deprecated
   */
  h1: '2.027rem',
};

export const fontWeight = {
  light: '300',
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

const base = {
  borderRadius,
  breakpoint,
  dimension,
  fontSize,
  fontWeight,
  shadow,
};

const light: DefaultTheme = {
  ...base,
  spec: ThemeSpecs.light,
  color: CourbetLightColorTheme,
};

const dark: DefaultTheme = {
  ...base,
  spec: ThemeSpecs.dark,
  color: CourbetDarkColorTheme,
};

const themes: { [K in ThemeName]: DefaultTheme } = {
  dark,
  light,
};

export default themes;
