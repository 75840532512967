import * as React from 'react';
import styled from 'styled-components';

import { truncateText, uppercaseText } from '../../../lib/css';
import { ChevronDownIcon } from '../../Icon';
import Interactive from '../../Interactive';

const _NavBarSectionContainer = styled.div`
  margin-top: 24px;
`;

export const NavBarSectionHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin: 0 0 8px 8px;
`;

const _TitleContainer = styled(Interactive)`
  align-items: center;
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  gap: 8px;
  overflow: hidden;
  user-select: none;
`;

const _ArrowContainer = styled.div`
  display: flex;
`;

const _Arrow = styled(ChevronDownIcon)<{ $isExpanded?: boolean }>`
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? '0' : '-90')}deg);
  transition: transform 150ms;
`;

const _Title = styled.small`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xxxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin: 0;
  ${uppercaseText()}
  ${truncateText()}
`;

export interface INavBarSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Whether or not the section can be collapsed. If falsy, the section is
   * always expanded and the title is not interactive.
   */
  collapsible?: boolean;
  /**
   * Controls displayed in the section header.
   */
  controls?: JSX.Element;
  /**
   * Whether or not the section is expanded to display its children.
   */
  isExpanded?: boolean;
  /**
   * Callback fired when the expanded state is toggled.
   */
  onToggle?: (isExpanded: boolean) => void;
  /**
   * Title for section header.
   */
  title: string;
}

const NavBarSection = React.forwardRef<HTMLDivElement, INavBarSectionProps>(
  ({ children, collapsible = true, controls, isExpanded = true, onToggle, ...props }, ref) => {
    const title = collapsible ? (
      <_TitleContainer aria-expanded={!!isExpanded} onClick={() => onToggle?.(!isExpanded)}>
        <_ArrowContainer>
          <_Arrow $isExpanded={isExpanded} />
        </_ArrowContainer>
        <_Title>{props.title}</_Title>
      </_TitleContainer>
    ) : (
      <_Title>{props.title}</_Title>
    );

    return (
      <_NavBarSectionContainer {...props} ref={ref}>
        <NavBarSectionHeader>
          {title}
          {controls}
        </NavBarSectionHeader>
        {collapsible ? isExpanded && children : children}
      </_NavBarSectionContainer>
    );
  }
);

NavBarSection.displayName = 'NavBarSection';

export default NavBarSection;
