import * as React from 'react';
import RBToast, { ToastProps } from 'react-bootstrap/Toast';
import RBToastContainer, { ToastContainerProps } from 'react-bootstrap/ToastContainer';
import styled from 'styled-components';

import { hexColorWithAlpha } from '../../lib/css';

import ToastContext, { ToastVariant } from './ToastContext';
import ToastHeader from './ToastHeader';

export type { ToastContainerProps, ToastVariant };

const _Toast = styled(RBToast)<{ $variant?: ToastVariant }>`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0 0 4px ${({ theme }) => theme.color.dropShadow};
  font-size: unset;

  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'danger':
      case 'success':
      case 'warning':
        return `
          background-color: ${hexColorWithAlpha(theme.color.variant[$variant].background, 1)};
          border-color: ${hexColorWithAlpha(theme.color.variant[$variant].border, 0.25)};
          color: ${theme.color.variant[$variant].text};
        `;
      default:
        return `
          background-color: ${theme.color.background400};
          border-color: ${theme.color.border};
        `;
    }
  }}
`;

const _ToastBody = styled(RBToast.Body)`
  padding: 8px 12px;
`;

const _ToastContainer = styled(RBToastContainer)`
  margin-top: ${({ theme }) => theme.dimension.header.height};
  padding: 8px;
`;

export interface IToastProps extends ToastProps {
  variant?: ToastVariant;
}

function Toast({ delay, onClose, variant, ...props }: IToastProps) {
  // Bypass RB's ToastContext and use our own so we can access `variant`.
  const context = React.useMemo(
    () => ({
      onClose,
      variant,
    }),
    [onClose, variant]
  );

  // Use refs to avoid restarting the autohide timer if these values change.
  const delayRef = React.useRef(delay);
  const onCloseRef = React.useRef(onClose);

  return (
    <ToastContext.Provider value={context}>
      <_Toast {...props} delay={delayRef.current} onClose={onCloseRef.current} $variant={variant} />
    </ToastContext.Provider>
  );
}

Toast.Container = _ToastContainer;
Toast.Body = _ToastBody;
Toast.Header = ToastHeader;

export default Toast;
