import { gql, useQuery } from '@apollo/client';
import { Paths, RTElementProp, RTMeetingRefElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';

import ResourceRef, { ResourceType } from '../../../../Resource/ResourceRef';
import { IRTElementProps } from '../../../IRTElementProps';
import VoidInlineElement from '../../common/VoidInlineElement';

import {
  BATCHED__MeetingRefElement_MeetingQuery,
  BATCHED__MeetingRefElement_MeetingQueryVariables,
} from './___generated___/MeetingRefElement.types';

export interface IBaseMeetingRefElementProps extends IRTElementProps<RTMeetingRefElement> {
  canSee?: boolean;
  name?: string;
  permalink?: string;
  selected?: boolean;
}

export function BaseMeetingRefElement(props: IBaseMeetingRefElementProps) {
  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <ResourceRef
        canSeeResource={props.canSee ?? false}
        editable={props.editable}
        resourceId={props.element[RTElementProp.MEETING_REF__MEETING_ID]}
        resourcePermalink={props.permalink}
        resourceTitle={props.name}
        resourceType={ResourceType.MEETING}
        selected={props.selected ?? false}
      />
    </VoidInlineElement>
  );
}

const MeetingQuery = gql`
  # Batched query
  query BATCHED__MeetingRefElement_MeetingQuery($id: ID!) {
    meeting(id: $id) {
      id
      name
    }
  }
`;

export default function MeetingRefElement(props: IRTElementProps<RTMeetingRefElement>) {
  const selected = useSelected();
  const discussionQuery = useQuery<
    BATCHED__MeetingRefElement_MeetingQuery,
    BATCHED__MeetingRefElement_MeetingQueryVariables
  >(MeetingQuery, {
    variables: { id: props.element[RTElementProp.MEETING_REF__MEETING_ID] ?? '' },
    skip: !props.element[RTElementProp.MEETING_REF__MEETING_ID],
  });

  const canSeeMeeting = discussionQuery.loading || !!discussionQuery.data?.meeting?.id;
  const offsetSeconds = props.element[RTElementProp.MEETING_REF__OFFSET_SECONDS] ?? 0;

  return (
    <BaseMeetingRefElement
      {...props}
      canSee={canSeeMeeting}
      name={discussionQuery.data?.meeting?.name}
      permalink={Paths.MEETING(props.element[RTElementProp.MEETING_REF__MEETING_ID] ?? '', {
        offsetSec: Math.floor(+offsetSeconds),
      })}
      selected={selected}
    />
  );
}
