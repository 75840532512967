import { useLocalStorage } from '@rehooks/local-storage';
import * as React from 'react';

import SearchModal from '../../components/SearchModal';
import useBreakpoints from '../../hooks/useBreakpoints';

const MENU_TYPE_KEY = 'selectedMenuType';
const SHOW_MENU_KEY = 'showMenu';

export const SIDEBAR_MENU_ANIMATION_DURATION_MS = 250;

export enum MenuType {
  ASSISTANT = 'assistant',
  PEOPLE = 'chats',
  INBOX = 'inbox',
  RECENT = 'recent',
  SHORTCUTS = 'shortcuts',
  TEAMS = 'teams',
}

interface INavigationLayout {
  // Menu controls
  closeMenu: () => void;
  playMenuAnimation: boolean;
  selectedMenu: MenuType;
  setSelectedMenu: (menu?: MenuType) => void;
  visibleMenu?: MenuType;

  // Search modal
  showSearchModal: boolean;
  toggleSearchModal: () => void;
}

export const defaultNavLayoutContext: INavigationLayout = {
  closeMenu: () => null,
  playMenuAnimation: false,
  selectedMenu: MenuType.INBOX,
  setSelectedMenu: () => null,
  visibleMenu: undefined,

  showSearchModal: false,
  toggleSearchModal: () => null,
};

export const NavigationLayoutContext = React.createContext<INavigationLayout>(defaultNavLayoutContext);

interface INavigationLayoutProviderProps {
  defaultMenu?: MenuType;
  storageKeyPrefix: string;
}

export default function NavigationLayoutProvider({
  children,
  defaultMenu = MenuType.INBOX,
  storageKeyPrefix,
}: React.PropsWithChildren<INavigationLayoutProviderProps>) {
  const { isDesktopSize } = useBreakpoints();

  // Menu controls
  const [_showMenu, setShowMenu] = useLocalStorage<boolean>(`${storageKeyPrefix}::${SHOW_MENU_KEY}`);
  const showMenu = _showMenu ?? isDesktopSize;

  const [playMenuAnimation, _setPlayMenuAnimation] = React.useState(false);
  const playAnimation = () => {
    _setPlayMenuAnimation(true);
    setTimeout(() => _setPlayMenuAnimation(false), SIDEBAR_MENU_ANIMATION_DURATION_MS);
  };

  const [_selectedMenu, _setSelectedMenu] = useLocalStorage<MenuType>(`${storageKeyPrefix}::${MENU_TYPE_KEY}`);
  const selectedMenu = _selectedMenu ?? defaultMenu;
  const visibleMenu = showMenu ? selectedMenu : undefined; // menu that is shown on the screen

  const setSelectedMenu = React.useCallback(
    (menu?: MenuType) => {
      playAnimation();
      setShowMenu(true);
      if (menu) {
        _setSelectedMenu(menu);
      }
    },
    [_setSelectedMenu, setShowMenu]
  );

  const closeMenu = React.useCallback(() => {
    playAnimation();
    setShowMenu(false);
  }, [setShowMenu]);

  // Search modal
  const [showSearchModal, setShowSearchModal] = React.useState(false);
  const toggleSearchModal = React.useCallback(() => setShowSearchModal((prev) => !prev), []);

  const value = React.useMemo(
    () => ({
      closeMenu,
      playMenuAnimation,
      selectedMenu,
      setSelectedMenu,
      visibleMenu,

      showSearchModal,
      toggleSearchModal,
    }),
    [closeMenu, playMenuAnimation, selectedMenu, setSelectedMenu, visibleMenu, showSearchModal, toggleSearchModal]
  );

  return (
    <NavigationLayoutContext.Provider value={value}>
      {children}
      <SearchModal show={showSearchModal} onHide={toggleSearchModal} />
    </NavigationLayoutContext.Provider>
  );
}

export const useNavigationLayout = () => React.useContext(NavigationLayoutContext);
