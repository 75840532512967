import { gql, useMutation, useQuery } from '@apollo/client';
import { Typename } from '@rmvw/c-common';
import * as React from 'react';

import {
  CanHavePermissionRoleType,
  Discussion,
  MembershipStatus,
  PrivateChat,
  Replies,
  Team,
} from '../___generated___/globalTypes';
import { useUpdateGrantsForEntityMutation } from '../hooks/mutation/useUpdateGrantsForEntityMutation';

import {
  HM_UpdateAccountAccess,
  HM_UpdateAccountAccessVariables,
  HQ_GetPermissionScope,
  HQ_GetPermissionScopeVariables,
} from './___generated___/PermissionScopeProvider.types';

type PermissionScopeType = Typename<Discussion | PrivateChat | Replies | Team>;

interface IPermissionScopeProps {
  scopeId?: string;
}

interface IPermissionProvider {
  id: string;
  type: PermissionScopeType;
  canEditMembership: boolean;
  externallyVisible: boolean;
  grantTeamAccess: (teamId: string) => Promise<void>;
  grantAccountAccess: (accountId: string) => Promise<void>;
  denyAccountAccess: (accountId: string) => Promise<void>;
}

const PermissionScopeContext = React.createContext<IPermissionProvider | undefined>(undefined);

function BasePermissionScope(props: React.PropsWithChildren<Partial<IPermissionScopeProps>>) {
  const query = useQuery<HQ_GetPermissionScope, HQ_GetPermissionScopeVariables>(
    gql`
      query HQ_GetPermissionScope($id: ID!) {
        thread(id: $id) {
          id
          canEditMembership

          ... on Discussion {
            externallyVisible
          }

          ... on PrivateChat {
            externallyVisible
          }

          ... on Meeting {
            externallyVisible
          }

          # Replies inherit parent thread permissions
          ... on Replies {
            parentThreadEvent {
              id
              thread {
                id
                externallyVisible
              }
            }
          }

          ... on Team {
            externallyVisible
          }
        }
      }
    `,
    { variables: { id: props.scopeId ?? '' }, skip: !props.scopeId }
  );

  const [_updateAccess] = useMutation<HM_UpdateAccountAccess, HM_UpdateAccountAccessVariables>(
    gql`
      mutation HM_UpdateAccountAccess($input: UpdateMembershipInput!, $targetId: ID!) {
        updateMembership(input: $input) {
          account {
            id
            canSee(id: $targetId)
          }
          group {
            id
            membership {
              account {
                id
              }
              status
            }
            requests {
              account {
                id
              }
              status
            }
          }
        }
      }
    `
  );

  const thread = query.data?.thread;
  let _scopeThread: { id: string; __typename: string; externallyVisible: boolean } | null | undefined;
  if (thread?.__typename === 'Replies') {
    _scopeThread = thread.parentThreadEvent?.thread;
  } else {
    _scopeThread = thread;
  }
  const scopeThread = _scopeThread; // make const to help with type narrowing

  const [setGrantForEntity] = useUpdateGrantsForEntityMutation(scopeThread);

  const scope = React.useMemo(() => {
    if (!scopeThread?.id || !scopeThread?.__typename) {
      return undefined;
    }
    return {
      id: scopeThread.id,
      type: scopeThread.__typename as PermissionScopeType,
      canEditMembership: !!thread?.canEditMembership,
      externallyVisible: !!scopeThread.externallyVisible,
      grantTeamAccess: async (teamId: string) =>
        setGrantForEntity([
          {
            entity: { id: teamId, type: CanHavePermissionRoleType.TEAM },
            role: MembershipStatus.MEMBER,
          },
        ]).then((result) => Promise.resolve()),
      grantAccountAccess: (accountId: string) =>
        _updateAccess({
          variables: {
            input: {
              memberships: [
                {
                  accountId,
                  groupId: scopeThread.id,
                  groupType: scopeThread.__typename,
                  status: MembershipStatus.MEMBER,
                },
              ],
            },
            targetId: scopeThread.id,
          },
        }).then((result) => Promise.resolve()),
      denyAccountAccess: (accountId: string) =>
        _updateAccess({
          variables: {
            input: {
              memberships: [
                {
                  accountId,
                  groupId: scopeThread.id,
                  groupType: scopeThread.__typename,
                  status: MembershipStatus.DENIED,
                },
              ],
            },
            targetId: scopeThread.id,
          },
        }).then((result) => Promise.resolve()),
    };
  }, [
    _updateAccess,
    scopeThread?.__typename,
    scopeThread?.externallyVisible,
    scopeThread?.id,
    setGrantForEntity,
    thread?.canEditMembership,
  ]);

  return <PermissionScopeContext.Provider value={scope}>{props.children}</PermissionScopeContext.Provider>;
}

export default function PermissionScope(props: React.PropsWithChildren<IPermissionScopeProps>) {
  return <BasePermissionScope {...props} />;
}

/**
 * Blocks an existing permission scope from being inherited by child elements.
 */
export function PermissionScopeBlocker(props: React.PropsWithChildren<unknown>) {
  return <BasePermissionScope>{props.children}</BasePermissionScope>;
}

export const usePermissionScope = () => React.useContext(PermissionScopeContext);

export function DebugPermissionScope({
  children,
  id = 'id',
  type = 'Discussion',
  canEditMembership = true,
  externallyVisible = false,
  grantTeamAccess = async (teamId) => {},
  grantAccountAccess = async (accountId) => {},
  denyAccountAccess = async (accountId) => {},
}: React.PropsWithChildren<Partial<IPermissionProvider>>) {
  return (
    <PermissionScopeContext.Provider
      value={{ id, type, canEditMembership, externallyVisible, grantTeamAccess, grantAccountAccess, denyAccountAccess }}
    >
      {children}
    </PermissionScopeContext.Provider>
  );
}
