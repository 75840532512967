import styled from 'styled-components';

const AvatarShell = styled.div.attrs((props: { color?: string; size?: number; thickness?: number }) => props)`
  aspect-ratio: 1;
  border-color: ${(props) => props.color};
  border-radius: 50%;
  border-style: ${(props) => (props.color ? 'solid' : undefined)};
  border-width: ${(props) => props.thickness ?? (props.color ? 2 : undefined)}px;
  height: ${(props) => props.size}px;
  overflow: hidden;
  position: relative;

  /** 
   * Fix for Safari not masking underlying videos according to border-radius specified above.
   * The trick here is that by setting a no-op translateZ we force rendering of this element
   * via the GPU which apparently supports videos cropping much better.
   */
  transform: translateZ(0);
  width: ${(props) => (props.size ? `${props.size}px` : '100%')};
`;

export default AvatarShell;
