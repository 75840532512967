import { gql, useLazyQuery, useMutation } from '@apollo/client';

import Logger from '../../lib/observability/Logger';

import {
  EditParent,
  EditParentVariables,
  GetPrivateChat,
  GetPrivateChatVariables,
  IsChildDiscussion,
  IsChildDiscussionVariables,
} from './___generated___/hooks.types';

export interface IDiscussionParent {
  id: string;
  name: string;
  permalink: string;
}

const EditParentMutation = gql`
  mutation EditParent($input: SetThreadParentInput!) {
    setThreadParent(input: $input) {
      id
    }
  }
`;

export function useEditParentMutation() {
  const [_editOrigin, result] = useMutation<EditParent, EditParentVariables>(EditParentMutation);

  async function editOrigin(threadId: string, parentThreadId: string | null) {
    try {
      await _editOrigin({ variables: { input: { threadId, parentThreadId } } });
    } catch (err) {
      Logger.error(err as Error, 'Could not edit origin');
    }
  }

  return [editOrigin, result] as const;
}

const GetPrivateChatQuery = gql`
  query GetPrivateChat($accountId: ID) {
    account(id: $accountId) {
      id
      privateChat {
        id
      }
    }
  }
`;

export function useGetPrivateChatQuery() {
  const [_getPrivateChat, result] = useLazyQuery<GetPrivateChat, GetPrivateChatVariables>(GetPrivateChatQuery);
  async function getPrivateChat(accountId: string) {
    try {
      return await _getPrivateChat({
        variables: {
          accountId: accountId,
        },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not get private chat');
    }
  }

  return [getPrivateChat, result] as const;
}

const isChildDiscussionQuery = gql`
  query IsChildDiscussion($discussionId: ID, $threadId: ID) {
    discussion(id: $discussionId) {
      id
      isChild(threadId: $threadId)
    }
  }
`;

export function useIsChildDiscussionQuery(discussionId: string) {
  const [_isChild, result] = useLazyQuery<IsChildDiscussion, IsChildDiscussionVariables>(isChildDiscussionQuery);
  async function isChild(threadId: string) {
    try {
      return await _isChild({
        variables: {
          discussionId: discussionId,
          threadId: threadId,
        },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not check if discussion is child');
    }
  }

  return [isChild, result] as const;
}
